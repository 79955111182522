import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
} from "@tabler/icons";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
};

const bulkAccess = ['7001029850','9719836187','9667530528','9891774071','7701974309','8374454823','8178277221','7532035127','9268096291','8826956878']

const agentRole = localStorage.getItem("setRole");
const agentType = localStorage.getItem("agentType");

const bulkOrderCreate=[...bulkAccess,'9911579990','9315405080']
const bulkOrderApproval = [...bulkAccess,'9911579990','9315405080','8800970879','8826193896']
const bulkOrderList = [...bulkAccess,'9873380793','8700175494','8800970879','8826193896']
const bulkShipOrder = [...bulkAccess,'9873380793','8700175494','9911579990','9315405080','8800970879','8826193896']




//-----------------------|| Customer MENU ITEMS ||-----------------------//

const phoneNumber = localStorage.getItem("phoneNumber");
export const Bulk = {
  id: "Bulk",
  title: <FormattedMessage id="Bulk" />,
  type: "group",
  children: [
    (bulkOrderCreate.includes(phoneNumber)) && 
    {
      id: "Bulk Order Create",
      title: <FormattedMessage id="Bulk Order Create" />,
      type: "item",
      url: "/bulk/ordercreate",
    },
   ( bulkOrderApproval.includes(phoneNumber) || (agentRole==5&& agentType=='BULK')) &&  {
      id: "Bulk Order Approval by SCM",
      title: <FormattedMessage id="Bulk Order Approval" />,
      type: "item",
      url: "/bulk/bulkorderapprovalforscm",
    },
    (bulkOrderList.includes(phoneNumber)) &&  {
      id: "Bulk Order List",
      title: <FormattedMessage id="Bulk Order List" />,
      type: "item",
      url: "/bulk/bulkorderlist",
    },
    (bulkShipOrder.includes(phoneNumber) || (agentRole==5&& agentType=='BULK') )&&  {
      id: "Bulk Ship Status",
      title: <FormattedMessage id="Bulk Ship Order" />,
      type: "item",
      url: "/bulk/bulkshipstatus",
    },
    // bulkAccess.includes(phoneNumber) &&  {
    //   id: "Move Bulk Order",
    //   title: <FormattedMessage id="Move Bulk Order" />,
    //   type: "item",
    //   url: "/bulk/moveBulkOrder",
    // },
  ],
};
