import PropTypes from 'prop-types';
import React, { useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';

// project imports
// import MainCard from '../../../ui-component/cards/MainCard';
import MainCard from '../../ui-component/cards/MainCard';
import SkeletonEarningCard from '../../ui-component/cards/Skeleton/EarningCard';
// import SkeletonEarningCard
// assets
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// style constant
const useStyles = makeStyles((theme) => ({
    content: {
        padding: '20px 0px !important'
    },
    cardHeading: {
        fontSize: '30px',
        fontWeight: 900,
        marginTop: 10,
        position: 'absloute',
        bottom: 0,
        color: '#333'
    },
    subHeading: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#000 '

    },
}));

//===========================|| DASHBOARD DEFAULT - EARNING CARD ||===========================//

const DataCard = ({ isLoading, bgc, name, value, totalPurchase, button, ButtonFunction }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isButton, setIsButton] = useState(false);

    return (
        <React.Fragment>
            {isLoading ? (
                <SkeletonEarningCard />
            ) : (
                <MainCard border={false} style={{
                    backgroundColor: bgc,
                    color: '#fff',
                    overflow: 'hidden',
                    position: 'relative',
                    height: "100%"
                }} contentClass={classes.content}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                        <Grid item >
                            <Typography textAlign='center' className={classes.subHeading}>{name}</Typography>
                        </Grid>
                        <Grid>
                            <Typography className={classes.cardHeading}> {totalPurchase ? `${totalPurchase} (${value})` : value}  </Typography>
                        </Grid>
                    </Grid>
                    {button ? <Grid container justifyContent={"center"}><a href="#table" style={{ color: 'white', textDecoration: 'none' }} onClick={ButtonFunction}> <Button variant='contained' size="small" > View</Button></a></Grid> : null}
                </MainCard>
            )}
        </React.Fragment>
    );
};

DataCard.propTypes = {
    isLoading: PropTypes.bool
};

export default DataCard;
