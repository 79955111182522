import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { TOGGLE_SHOW_LEAD } from '../../../store/actions';
import { Link } from 'react-router-dom';
import MinimizeIcon from '@material-ui/icons/Minimize';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css'

const useStyles = makeStyles({
    textBold: {
        fontSize: 18,
        color: '#000',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        marginBottom: 5
    },
    textLight: {
        fontSize: 18,
        color: '#000',
        textTransform: 'capitalize',
        marginBottom: 5,
        paddingLeft: 5
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    },
    detailsButton: {
        backgroundColor: '#F94C66',
        color: "#fff",
        textDecoration: 'none',
        padding: '7px 10px',
        boxShadow: "1px 2px 10px #000",
        borderRadius: 5
    },
    callBUtton: {
        backgroundColor: '#3AB0FF',
        textDecoration: 'none',
        padding: '7px 10px',
        boxShadow: "1px 2px 10px #000",
        color: '#fff',
        borderRadius: 5
    }
})


const ChatBox = ({ lead, index }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const toggleButton = () => {
        dispatch({
            type: 'TOGGLE_SHOW_LEAD',
            payload: {
                index: index,
                show: !lead.show
            }
        })
    }

    const nameTrim = (name) => {
        if (name.length > 25) {
            return `${name.slice(0, 24)}...`
        }
        else return name
    }

    return (
        lead.show ?
            <Card sx={{ width: 310 }} style={{ zIndex: 10, marginLeft: 10, backgroundColor: '#fff', boxShadow: '.1px 5px 10px #000', borderRadius: '10px 10px 0 0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, alignItems: 'center', backgroundColor: "#1B2430", }}>
                    <Typography style={{ fontSize: 18, color: '#fff', textTransform: 'capitalize' }}>{nameTrim(lead?.name || null)}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'baseline' }}>
                        <ExpandMoreIcon style={{ cursor: 'pointer', fontSize: 30 }} onClick={toggleButton} />
                    </div>
                </div>
                <div style={{ padding: 15 }}>
                    {/* <div className={classes.flex}>
                        <Typography className={classes.textBold}>Lead Name :- {' '}</Typography>
                        <Typography className={classes.textLight}> {nameTrim(lead?.name||null)}</Typography>
                    </div> */}
                    <div className={classes.flex}>
                        <Typography className={classes.textBold}>Number :- </Typography>
                        <Typography className={classes.textLight}> {lead?.phoneNumber || null}</Typography>
                    </div>
                    <div className={classes.flex}>
                        <Typography className={classes.textBold}>Last Update :- </Typography>
                        <Typography className={classes.textLight}>{new Date(lead?.updatedAt).toDateString()}</Typography>
                    </div>
                    <div className={classes.flex}>
                        <Typography className={classes.textBold}>Last Status :- </Typography>
                        <Typography className={classes.textLight}>{lead?.recentStatus || null}</Typography>
                    </div>
                    <div className={classes.flex}>
                        <Typography className={classes.textBold}>Last Comment :-</Typography>
                        <Typography className={classes.textLight}>{lead?.remark || null}</Typography>
                    </div>


                </div>
                <CardActions>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Link to={`/sales/updatelead/${lead._id}`} className={classes.detailsButton} variant='contained' >View Details</Link>
                        <Link to={`/sales/updatelead/${lead._id}`} className={classes.callBUtton} variant='contained' >Click To Call</Link>

                    </div>
                </CardActions>
            </Card> : <Card sx={{ width: 310 }} style={{ zIndex: 10, marginLeft: 10, backgroundColor: '#fff', boxShadow: '.1px 5px 10px #000', borderRadius: '10px 10px 0 0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10, alignItems: 'center', backgroundColor: "#1B2430", }}>
                    <Typography style={{ fontSize: 18, color: '#fff', textTransform: 'capitalize' }}>{nameTrim(lead?.name || null)}</Typography>
                    <ExpandLessIcon style={{ cursor: 'pointer', fontSize: 30 }} onClick={toggleButton} />
                </div>

            </Card>
    )
}

export default ChatBox