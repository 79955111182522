import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  CardContent,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Select,
  FormControl,
  Button,
  Paper,
  Collapse,
  Box,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Alert,
  DialogActions,
  Divider,
  MenuItem,
  Switch,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import Chip from "../../ui-component/extended/Chip";
// project imports
import MainCard from "../../ui-component/cards/MainCard";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import axios from "axios";
import ApiInstance from "../../Services/ApiInstance";
import moment from "moment";
import Ordershippopup from "../Vendor/Ordershippopup";
import AlertModal from "./AlertModal";
import { LoadingButton } from "@material-ui/lab";
import SubCard from "../../ui-component/cards/SubCard";
import { CheckBox } from "@mui/icons-material";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

//-----------------------|| TABLE - STICKY HEADER ||-----------------------//

export default function BulkOrderApprovalScm() {
  const classes = useStyles();
  const router = useHistory();
  const [page, setPage] = React.useState(0);
  const [isPendingOrder, setIsPendingOrder] = useState(false);
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const agentRole = localStorage.getItem("setRole");
  const agentAccessRole = ["4", "5"];
  const [loading, setLoading] = useState(false);
  const [orderdata, setOrderData] = useState([]);
  const [pageLoad, togglePageLoad] = useState(false);
  const [pendingProductData, setPendingProductData] = useState([]);
  const [pendingDownloadData, setPendingDownloadData] = useState([]);

  const headers = [
    { key: "_id", label: "Product Id" },
    { key: "name", label: "Name" },
    { key: "EANCode", label: "EANCode" },
    { key: "HSNCode", label: "HSNCode" },
    { key: "pendingQty", label: "Pending Quantity" },
  ];

  const dataLoad = async () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/getBulkOrderList", {
        status: 1,
        startDate: date.startDate,
        endDate: date.endDate,
        stage: agentRole == 5 ? 2 : 1,
      })
      .then((res) => {
        if (res.data.success) {
          console.log("resssss", res.data);
          setOrderData(res.data.data[0].data || []);
          togglePageLoad(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error of get data", error);
        setLoading(false);
      });
  };

  const getPendingOrder = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/sales/getBulkPendingOrders", {
        startDate: date.startDate,
        endDate: date.endDate,
      })
      .then((res) => {
        if (res.data.success) {
          console.log("resssss", res.data);
          setPendingProductData(res.data.data[0]?.data || []);
          setPendingDownloadData(res.data.data[0]?.dataForDownload || []);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error of get data", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isPendingOrder) {
      getPendingOrder();
    }
  }, [isPendingOrder]);

  useEffect(() => {
    dataLoad();
  }, []);

  if (!pageLoad) {
    return (
      <Paper align="center">
        <img
          alt="Example Alt"
          style={{ width: "200px", height: "130px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
          align="center"
        />
      </Paper>
    );
  }

  const CollapesRowForChild = ({ order, index }) => {
    let orignalOrder = { ...order };
    const [open, setOpen] = useState(false);
    const [childOrders, setChildOrders] = useState([]);
    const [isOrderComplete, setIsOrderComplete] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [totalPay, setTotalPay] = useState({
      unapproveTotal: 0,
      approveTotal: 0,
      approveOrderId: null,
      unApproveOrderId: null,
    });

    // console.log('prderrrr',order)
    let obj = {
      approve: 0,
      unApprove: 0,
    };

    const checkQuantityFunction = () => {
      try {
        if (orignalOrder?.products.length > 0 && !open) {
          let temp = [];
          orignalOrder.products.forEach((pro) => {
            if (orignalOrder.childBulkOrders.length > 0) {
              orignalOrder.childBulkOrders.forEach((childProduct, index) => {
                if (childProduct.status == 2) {
                  setIsCancel(true);
                }
                if (
                  childProduct.orderStage == 2 ||
                  childProduct.orderStage == 3
                ) {
                  childProduct.products.forEach((approveOrder) => {
                    if (pro.productId == approveOrder.productId) {
                      pro.dispatchQuantity =
                        index == 0 ? 0 : pro?.dispatchQuantity || 0;
                      pro.dispatchQuantity =
                        approveOrder?.quantity + (pro?.dispatchQuantity || 0);
                      pro.pendingQuantity = 0;
                    }
                  });
                }

                if (childProduct.orderStage == 1) {
                  childProduct.products.forEach((unapproveOrder) => {
                    if (pro.productId == unapproveOrder.productId) {
                      pro.pendingQuantity += unapproveOrder.quantity;
                    }
                  });
                }
                if (childProduct.orderStage == 3) {
                  setIsOrderComplete(true);
                }
              });
            }
          });

          if (
            orignalOrder.childBulkOrders &&
            orignalOrder.childBulkOrders.length > 0
          ) {
            orignalOrder.childBulkOrders.forEach((childProduct, index) => {
              if (
                childProduct.orderStage == 2 ||
                childProduct.orderStage == 3
              ) {
                setTotalPay((pre) => ({
                  ...pre,
                  approveTotal:
                    pre.approveTotal + childProduct?.totalPayableAmount || 0,
                  approveOrderId: childProduct._id,
                }));
              }
              if (childProduct.orderStage == 1) {
                setTotalPay((pre) => ({
                  ...pre,
                  unapproveTotal:
                    pre.unapproveTotal + childProduct?.totalPayableAmount || 0,
                  unApproveOrderId: childProduct._id,
                }));
              }
            });
          }
          // console.log("oriiiii", orignalOrder.products);
          // setTotalPay({
          //   approveTotal:obj.approve,
          //   unapproveTotal:obj.unApprove
          // })
          setChildOrders(orignalOrder.products);
          setOpen(true);
        } else setOpen(false);
      } catch (error) {
        console.log("error ", error);
      }
    };

    const approveOrder = (orderId) => {
      let isConfirm = window.confirm(
        "Are you sure you want to approve this order !"
      );
      if (isConfirm) {
        axios
          .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
            orderId: orderId,
            action: "approved", // notApproved
          })
          .then((res) => {
            console.log("res of approve ", res.data);
            if (res.data.success) {
              alert("Order split successfully");
              window.location.reload();
            } else {
              alert(res.data?.message || "someting went wrong");
            }
          })
          .catch((error) => {
            console.log("error of approve", error);
            alert("someting went wrong");
          });
      }
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            {order.status == 8 ? (
              <IconButton
                aria-label="expand row"
                size="small"
                // onClick={() => {
                //   setOpen(!open);
                // }}
                onClick={() => {
                  checkQuantityFunction();
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            ) : null}
          </TableCell>
          <TableCell>{order.numericOrderId}</TableCell>
          {/* <TableCell>{order.parentBulkNumericOrderId}</TableCell> */}
          <TableCell>{order?.name}</TableCell>
          <TableCell>{order?.phoneNumber}</TableCell>
          <TableCell>{order?.agentName}</TableCell>
          <TableCell>
            {moment(order.createdAt).format("HH:mm A - DD/MM/YYYY")}
          </TableCell>
          <TableCell>{order?.seller?.gstNumber || "not found"}</TableCell>
          <TableCell>{order?.seller?.businessName || "not found"}</TableCell>
          <TableCell>{order?.totalPayableAmount}</TableCell>
          <TableCell>
            {order?.status == 8 ? (
              <Chip label="Splited" chipcolor="primary" />
            ) : (
              <Chip label="New" chipcolor="secondary" />
            )}
          </TableCell>
          <TableCell>
            <Button
              onClick={() => {
                agentRole == 4
                  ? router.push(
                      `/editBulkOrderScm/${order._id}/${
                        order.status == 8 ? false : true
                      }/true`
                    )
                  : router.push(`/editBulkOrderScm/${order._id}/false/true`);
              }}
              target="_blank"
              variant="contained"
            >
              {agentRole == 5 || agentRole == 14
                ? "Order Details"
                : order.status == 8
                ? "Order Details"
                : "Approve Order"}{" "}
            </Button>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>
                        {" "}
                        {isOrderComplete
                          ? "Order Approved"
                          : "Dispatch Quanity"}
                      </TableCell>
                      <TableCell>Pending Quanity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {childOrders &&
                      childOrders.length > 0 &&
                      childOrders.map((chOrder) => (
                        <TableRow>
                          <TableCell>{chOrder?.name}</TableCell>
                          <TableCell>
                            {chOrder?.dispatchQuantity || 0}
                          </TableCell>
                          <TableCell>{chOrder?.pendingQuantity || 0}</TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell variant="head">Total Payable</TableCell>
                      <TableCell variant="head">
                        {totalPay.approveTotal}
                      </TableCell>
                      <TableCell variant="head">
                        {totalPay.unapproveTotal}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Action</TableCell>
                      <TableCell variant="head">
                        {!isCancel ? (
                          !isOrderComplete &&
                          (agentRole == 5 || agentRole == 14) && (
                            <Button
                              onClick={() => {
                                // router.push(`/editBulkOrderScm/${totalPay.approveOrderId}`);
                                (agentRole == 5 || agentRole == 14) &&
                                  router.push(
                                    `/editBulkOrderScm/${totalPay.approveOrderId}/true/false`
                                  );
                              }}
                              variant="contained"
                              target="_blank"
                            >
                              {"Approve order"}{" "}
                            </Button>
                          )
                        ) : (
                          <Typography color={"red"}>Canceled</Typography>
                        )}
                      </TableCell>
                      <TableCell variant="head">
                        {agentRole == 4 && !isCancel ? (
                          <Button
                            onClick={() => {
                              router.push(
                                `/editBulkOrderScm/${totalPay.unApproveOrderId}/true/false`
                              );
                            }}
                            target="_blank"
                            variant="contained"
                          >
                            {"Approve order"}{" "}
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };
  const CollapesRowForPendingOrder = ({ order, index }) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{order?.parentBulkNumericOrderId}</TableCell>
          <TableCell>{order?.numericOrderId}</TableCell>
          <TableCell>{order?.name}</TableCell>
          <TableCell>{order?.phoneNumber}</TableCell>
          <TableCell>{order?.agentName}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Quanity</TableCell>
                      <TableCell>Mrp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order?.products &&
                      order?.products.length > 0 &&
                      order?.products.map((item) => (
                        <TableRow>
                          <TableCell>{item?.name}</TableCell>
                          <TableCell>{item?.quantity}</TableCell>
                          <TableCell>{item?.mrp}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <>
      <SubCard
        content={false}
        title="Bulk Orders"
        secondary={
          <div>
            <Switch
              checked={isPendingOrder}
              onChange={(v) => {
                setIsPendingOrder(v.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />
            <Typography>Pending Orders</Typography>
          </div>
        }
      >
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={3} item>
            <TextField
              id="datetime-local"
              label="Start Date"
              type="date"
              defaultValue={date.startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: "100%",
              }}
              onChange={(e) =>
                setDate({
                  ...date,
                  startDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="datetime-local"
              label="End Date"
              type="date"
              defaultValue={date.endDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: "100%",
              }}
              onChange={(e) =>
                setDate({
                  ...date,
                  endDate: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <LoadingButton
              variant="contained"
              style={{
                width: "100%",
              }}
              loadingIndicator="loading..."
              onClick={() => (isPendingOrder ? getPendingOrder() : dataLoad())}
              loading={loading}
            >
              Submit
            </LoadingButton>
          </Grid>
          {isPendingOrder && pendingDownloadData.length > 0 && (
            <Grid item xs={3}>
              <CSVLink
                style={{
                  textDecoration: "none",
                  border: "1px solid #999",
                  padding: "10px 20px",
                  backgroundColor: "#95c7f1",
                }}
                title="Download"
                data={pendingDownloadData}
                headers={headers}
                filename="Pending Products"
              >
                donwload
              </CSVLink>
            </Grid>
          )}
        </Grid>
        {!isPendingOrder && (
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid row container alignItems="center">
                <Grid item xs={12} lg={4} ml={2}>
                  <Button
                    onClick={() => dataLoad()}
                    variant="contained"
                    style={{ marginLeft: 10 }}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}

        {!isPendingOrder ? (
          <TableContainer style={{ overflowX: "auto" }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Order Id </TableCell>
                  {/* <TableCell>Parent Order Id </TableCell> */}
                  <TableCell>Name</TableCell>
                  <TableCell>PhoneNumber</TableCell>
                  <TableCell>Agent Name</TableCell>
                  <TableCell>Order Date </TableCell>
                  <TableCell>GST Number</TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Total Payabel</TableCell>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderdata &&
                  orderdata.length > 0 &&
                  orderdata.map((order, index) => (
                    <CollapesRowForChild order={order} index={index} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer style={{ marginTop: 10 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Parent Order Id</TableCell>
                  <TableCell>OrderId</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Agent Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingProductData &&
                  pendingProductData.length > 0 &&
                  pendingProductData.map((pro) => (
                    <CollapesRowForPendingOrder order={pro} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </SubCard>
    </>
  );
}
