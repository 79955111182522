import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { breakState, dialerState, storeId } from '../../../action/dialerAction';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  circle: {
    display: "flex",
    flexWrap: "wrap",
    height: " 20px",
    width: "20px",
    borderRadius: "50%",
    position: "relative",
    top: "1.6rem",
    left: "2rem",
    marginTop: '-1rem'
  },

}));

export default function DialerInitialState() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const dialerStoreValue = useSelector(state => state.dialer)


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [initalState, setInitialState] = React.useState('1')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectReadyState = (value) => {
    setInitialState(value);
    if (value == '4' || value == '1') {
      dispatch(breakState(''))
    }
    dispatch(dialerState(value))
    setAnchorEl(null);

  }

  const [breakType, setBreakType] = React.useState('');

  const getBreakType = () => {
    if (dialerStoreValue.breakValue == "MORNING_BREAK" || dialerStoreValue.breakValue == "LUNCH" || dialerStoreValue.breakValue == "TRAINING" || dialerStoreValue.breakValue == "EVENING_BREAK" || dialerStoreValue.breakValue == "QUICK_BREAK") {
      setBreakType('4')
      dispatch(dialerState('3'))
    } else {
      setBreakType('')
    }
  }

  const setColor = () => {
    switch (dialerStoreValue.readyValue) {
      case '1':
        return 'success'
      case '2':
        return 'error'
      case '3':
        return 'warning'
      case '4':
        return 'success'
      default:
        break;
    }
  }

  const setBGColor = () => {
    switch (dialerStoreValue.readyValue) {
      case '1':
        return 'green'
      case '2':
        return 'red'
      case '3':
        return 'yellow'
      case '4':
        return 'green'
      default:
        break;
    }
  }

  React.useEffect(() => {
    getBreakType();
  }, [dialerStoreValue.breakValue]);


  React.useEffect(() => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_PATH}/role5/markAttendance`,
      data: {
        type: 1
      }
    }).then(res => {
      dispatch(storeId(res.data.data._id))
    }).catch(err => {
      console.log(err)
    })
  }, []);

  /* React.useEffect(() => {
    if (dialerStoreValue.breakValue == "MORNING_BREAK" || dialerStoreValue.breakValue == "LUNCH" || dialerStoreValue.breakValue == "TRAINING" || dialerStoreValue.breakValue == "EVENING_BREAK") {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_PATH}/role5/markAttendance`,
        data: {
          type: '1',
          attendanceId: dialerStoreValue.attendance_Id,
        }
      }).then(res => {
        // dispatch(storeId(res.data.data._id))
      }).catch(err => {
        console.log(err)
      })
    } else {
      console.log('');
    }
  }, [dialerStoreValue.readyValue]); */

  React.useEffect(() => {
    if (dialerStoreValue.readyValue == '2') {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_PATH}/role5/markAttendance`,
        data: {
          type: '2',
          attendanceId: dialerStoreValue.attendance_Id
        }
      }).then(res => {
        // dispatch(storeId(res.data.data._id))
      }).catch(err => {
        console.log(err)
      })
    } else {
      console.log('')
    }
  }, [dialerStoreValue.readyValue]);

  React.useEffect(() => {
    if (dialerStoreValue.readyValue == '4' && dialerStoreValue.attendance_Id !== '') {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_PATH}/role5/markAttendance`,
        data: {
          type: '4',
          attendanceId: dialerStoreValue.attendance_Id
        }
      }).then(res => {
        // dispatch(storeId(res.data.data._id))
      }).catch(err => {
        console.log(err)
      })
    } else {
      console.log('')
    }
  }, [dialerStoreValue.readyValue, dialerStoreValue.attendance_Id]);

  React.useEffect(() => {
    if (dialerStoreValue.breakValue == "MORNING_BREAK" || dialerStoreValue.breakValue == "LUNCH" || dialerStoreValue.breakValue == "TRAINING" || dialerStoreValue.breakValue == "EVENING_BREAK" || dialerStoreValue.breakValue == "QUICK_BREAK") {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_PATH}/role5/markAttendance`,
        data: {
          type: '3',
          attendanceId: dialerStoreValue.attendance_Id,
          breakReasons: dialerStoreValue.breakValue
        }
      }).then(res => {
        // dispatch(storeId(res.data.data._id))
      }).catch(err => {
        console.log(err)
      })
    } else {
      console.log('');
    }
  }, [dialerStoreValue.breakValue]);


  React.useEffect(() => {
    if (dialerStoreValue.readyValue == '1') {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_PATH}/role5/markAttendance`,
        data: {
          type: '1',
          attendanceId: dialerStoreValue.attendance_Id
        }
      }).then(res => {
        // dispatch(storeId(res.data.data._id))
      }).catch(err => {
        console.log(err)
      })
    } else {
      console.log('')
    }
  }, [dialerStoreValue.readyValue]);

  console.log(dialerStoreValue, "dialerStoreValue...")

  return (
    <div>
      <span className={classes.circle} style={{ backgroundColor: setBGColor() }}></span>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        color={setColor()}
        style={{ marginLeft: "1rem" }}
      >
        READY
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => selectReadyState('1')}>START</MenuItem>
        <MenuItem onClick={() => selectReadyState('2')}>STOP</MenuItem>
        <MenuItem onClick={() => selectReadyState('4')}>BREAK END</MenuItem>
        {/* <MenuItem onClick={() => selectReadyState('Traning')}>Traning</MenuItem>
        <MenuItem onClick={() => selectReadyState('Eve Break')}>Eve Break</MenuItem>
        <MenuItem onClick={() => selectReadyState('Ready')}>Ready</MenuItem> */}


      </Menu>
    </div>
  );
}
