import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingStore,
    IconShoppingCart,
    IconClipboardList
} from "@tabler/icons";

// constant
const icons = {
    IconUserCheck: IconUserCheck,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconBuildingStore: IconBuildingStore,
    IconShoppingCart: IconShoppingCart,
    IconClipboardList: IconClipboardList
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//

export const fcAdmin = {
    id: "FC Admin",
    title: <FormattedMessage id="FC Admin" />,
    type: "group",
    children: [
        {
            id: "Complaints",
            title: <FormattedMessage id="Complaints" />,
            type: "collapse",
            icon: icons["IconBuildingStore"],
            children: [
                {
                    id: "Fc Complaints",
                    title: <FormattedMessage id="Fc Complaints" />,
                    type: "item",
                    url: "/fcadmin/fc-complaints",
                    breadcrumbs: true,
                },
                {
                    id: "Fc Users Complaints",
                    title: <FormattedMessage id="Fc Users Complaints" />,
                    type: "item",
                    url: "/fcadmin/fc-users-complaints",
                    breadcrumbs: true,
                },
            ],
        },
        {
            id: "FC Wise Details",
            title: <FormattedMessage id="FC Wise Details" />,
            type: "collapse",
            icon: icons["IconBuildingStore"],
            children: [
                {
                    id: "Fc Purchase Orders",
                    title: <FormattedMessage id="Fc Purchase Orders" />,
                    type: "item",
                    url: "/fcadmin/fc-purchase-orders",
                    breadcrumbs: true,
                },
                {
                    id: "Fc Wise Orders List",
                    title: <FormattedMessage id="Fc Wise Orders List" />,
                    type: "item",
                    url: "/fcadmin/fc-wise-orders-list",
                    breadcrumbs: true,
                },
                {
                    id: "Florida Orders List",
                    title: <FormattedMessage id="Florida Orders List" />,
                    type: "item",
                    url: "/fcadmin/florida-orders-list",
                    breadcrumbs: true,
                },
                {
                    id: "Fc Group Orders List",
                    title: <FormattedMessage id="Fc Group Orders List" />,
                    type: "item",
                    url: "/fcadmin/fc-group-orders-list",
                    breadcrumbs: true,
                },
                {
                    id: "Fc Wise Settlements",
                    title: <FormattedMessage id="Fc Wise Settlements" />,
                    type: "item",
                    url: "/fcadmin/fc-wise-settlements",
                    breadcrumbs: true,
                },
                {
                    id: "Fc Wise Inventory",
                    title: <FormattedMessage id="Fc Wise Inventory" />,
                    type: "item",
                    url: "/fcadmin/fc-wise-Inventory",
                    breadcrumbs: true,
                },
                {
                    id: "Place Order For Fc",
                    title: <FormattedMessage id="Place Order For Fc" />,
                    type: "item",
                    url: "/fcadmin/place-order-for-fc",
                    breadcrumbs: true,
                },
                // {
                //     id: "Fc GRN",
                //     title: <FormattedMessage id="Fc GRN" />,
                //     type: "item",
                //     url: "/fcadmin/Fc-Grn",
                //     breadcrumbs: true,
                // },
            ],
        },
        {
            id: "Admin",
            title: <FormattedMessage id="Admin" />,
            type: "collapse",
            icon: icons["IconBuildingStore"],
            children: [
                {
                    id: "Create New Fc",
                    title: <FormattedMessage id="Create New Fc" />,
                    type: "item",
                    url: "/fcadmin/create-new-fc",
                    breadcrumbs: true,
                },
                {
                    id: "Fc List",
                    title: <FormattedMessage id="Fc List" />,
                    type: "item",
                    url: "/fcadmin/fc-list",
                    breadcrumbs: true,
                },
                {
                    id: "Amount Collection-FC",
                    title: <FormattedMessage id="Amount Collection-FC" />,
                    type: "item",
                    url: "/fcadmin/amount-collection-fc",
                    breadcrumbs: true,
                },
                {
                    id: "Order Forecast",
                    title: <FormattedMessage id="Order Forecast" />,
                    type: "item",
                    url: "/fcadmin/order-forecast",
                    breadcrumbs: true,
                },
                {
                    id: "Dispatch FC Orders",
                    title: <FormattedMessage id="Dispatch FC Orders" />,
                    type: "item",
                    url: "/fcadmin/dispatch-orders",
                    breadcrumbs: true,
                }
            ],
        },
        {
            id: "Fc Reports",
            title: <FormattedMessage id="Fc Reports" />,
            type: "collapse",
            icon: icons["IconClipboardList"],
            children: [
                {
                    id: "Settlement Report",
                    title: <FormattedMessage id="Settlement Report" />,
                    type: "item",
                    url: "/fcadmin/settlement-report",
                    breadcrumbs: true,
                },
                {
                    id: "Order Wise Settlement Report",
                    title: <FormattedMessage id="Order Wise Settlement Report" />,
                    type: "item",
                    url: "/fcadmin/order-wise-settlement-report",
                    breadcrumbs: true,
                },
                {
                    id: "Total Orders Amount",
                    title: <FormattedMessage id="Total Orders Amount" />,
                    type: "item",
                    url: "/fcadmin/total-orders-amount",
                    breadcrumbs: true,
                },
            ],
        }

    ],
};
