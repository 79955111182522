import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
} from "@tabler/icons";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconClipboardList: IconClipboardList,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//

let reports = [];
const role = localStorage.getItem("setRole");


const viewAsPerRole = () => {
  if (role == "4" || role == "1") {
    return [
      {
        id: "Reports",
        title: <FormattedMessage id="Reports" />,
        type: "collapse",
        icon: icons["IconClipboardList"],
        children: [
          {
            id: "Seller With Florida Orders",
            title: <FormattedMessage id="Seller With Florida Orders" />,
            type: "item",
            url: "/reports/sellerwithfloridaorders",
          },
          {
            id: "mapwisereport",
            title: <FormattedMessage id="Map Wise Report" />,
            type: "item",
            url: "/reports/mapwisereport",
          },
          {
            id: "buyumedetails",
            title: <FormattedMessage id="BuyuMe Details" />,
            type: "item",
            url: "/reports/buyumedetails",
          },
          {
            id: "mostsellingproduct",
            title: <FormattedMessage id="Most Selling Product" />,
            type: "item",
            url: "/reports/mostsellingproduct",
          },
          {
            id: "mostsellingbrands",
            title: <FormattedMessage id="Most Selling Brands" />,
            type: "item",
            url: "/reports/mostsellingbrands",
          },
          {
            id: "BrandWiseRetailReport",
            title: <FormattedMessage id="Brand Wise Retail Report" />,
            type: "item",
            url: "/reports/BrandWiseRetailReport",
          },
          {
            id: "productorderdetails",
            title: <FormattedMessage id="Product Orders Details" />,
            type: "item",
            url: "/reports/productorderdetails",
          },
          {
            id: "referreport",
            title: <FormattedMessage id="Refer Report" />,
            type: "item",
            url: "/reports/referreport",
          },
          {
            id: "downloadreports",
            title: <FormattedMessage id="Download Report" />,
            type: "item",
            url: "/reports/downloadreports",
          },
          {
            id: "OrderDateCohort Online ",
            title: <FormattedMessage id="OrderDateCohort Online " />,
            type: "item",
            url: "/reports/OrderDateCohort",
          },
          {
            id: "OrderDateCohort Online with RTO",
            title: <FormattedMessage id="OrderDateCohort Online with RTO" />,
            type: "item",
            url: "/reports/OrderDateCohortRto",
          },
          {
            id: "OrderDateCohort Online(South Team)",
            title: <FormattedMessage id="OrderDateCohort Online(South Team)" />,
            type: "item",
            url: "/reports/OrderDateCohortSouth",
          },
          {
            id: "OrderDateCohort Online with RTO(South Team)",
            title: <FormattedMessage id="OrderDateCohort Online with RTO(South Team)" />,
            type: "item",
            url: "/reports/OrderDateCohortRtoSouth",
          },
          {
            id: "OrderDateCohort Offline",
            title: <FormattedMessage id="OrderDateCohort Offline" />,
            type: "item",
            url: "/reports/OrderDateOfflineCohort",
          },
          {
            id: "OrderDateCohort Offline with RTO",
            title: <FormattedMessage id="OrderDateCohort Offline with RTO" />,
            type: "item",
            url: "/reports/OrderDateOfflineCohortRto",
          },
          {
            id: "OrderDateCohort Bulk",
            title: <FormattedMessage id="OrderDateCohort Bulk" />,
            type: "item",
            url: "/reports/OrderDateBulkCohort",
          },
          {
            id: "DownloadcohortReport",
            title: <FormattedMessage id="DownLoad Date Cohort" />,
            type: "item",
            url: "/reports/DownloadDateCohort",
          },
          {
            id: "CacReport",
            title: <FormattedMessage id="Cac Report" />,
            type: "item",
            url: "/reports/CacReport",
          },
          {
            id: "NotAvailableProductsReport",
            title: <FormattedMessage id="Not Available Products Report" />,
            type: "item",
            url: "/reports/notavailableproductsreport",
          },
          {
            id: "ProductDiscountWiseReport",
            title: <FormattedMessage id="Product Discount Wise Report" />,
            type: "item",
            url: "/reports/ProductDiscountWiseReport",
          },
          {
            id: "MembershipReport",
            title: <FormattedMessage id="Membership Report" />,
            type: "item",
            url: "/reports/MembershipReport",
          },
          {
            id: "Expiring Refs Report",
            title: <FormattedMessage id="Expiring Refs Report" />,
            type: "item",
            url: "/reports/expiringRefsReport",
          },
          // {
          //   id: "CallerwiseSalesReport",
          //   title: <FormattedMessage id="Callerwise Sales Report" />,
          //   type: "item",
          //   url: "/reports/CallerwiseSalesReport",
          // },

          {
            id: "SellerGST",
            title: <FormattedMessage id="Seller GST List" />,
            type: "item",
            url: "/reports/sellergst",
          },
          {
            id: "monrhRTO",
            title: <FormattedMessage id="Month RTO Report" />,
            type: "item",
            url: "/reports/rto-report",
          },
          {
            id: "brandOrderPerPerson",
            title: <FormattedMessage id="Brands Order Per Seller" />,
            type: "item",
            url: "/reports/brandOrderPerPerson",
          },
          // {
          //   id: "organicInorginc",
          //   title: <FormattedMessage id="Organic &amp; Inorganic Report" />,
          //   type: "item",
          //   url: "/reports/organicInorganicReport",
          // },
          {
            id: "agentDealOrder",
            title: <FormattedMessage id="Agent Deal Order" />,
            type: "item",
            url: "/reports/agentDealOrder",
          },
          {
            id: "sellerRTOReport",
            title: <FormattedMessage id="Seller Wise RTO Report" />,
            type: "item",
            url: "/reports/sellerWiseRTOReport",
          },
          {
            id: "distributorReport",
            title: <FormattedMessage id="Distributor Report" />,
            type: "item",
            url: "/reports/distributorReport",
          },
          // {
          //   id: "offlineSeller",
          //   title: <FormattedMessage id="Offline Agent Report" />,
          //   type: "item",
          //   url: "/reports/offlineAgentReport",
          // },
          {
            id: "BuyumeOrderWiseReport",
            title: <FormattedMessage id="Buyume OrderWise Report" />,
            type: "item",
            url: "/reports/BuyumeOrderWiseReport",
          },
          {
            id: "Split Order Report",
            title: <FormattedMessage id="Split Order Report" />,
            type: "item",
            url: "/reports/splitOrderReport",
          },
          {
            id: "Forcast Inventory Report",
            title: <FormattedMessage id="Forcast Inventory Report" />,
            type: "item",
            url: "/reports/forcast-inventoryreport",
          },
          {
            id: "Product Wise Availability Report",
            title: <FormattedMessage id="Product Wise Availability Report" />,
            type: "item",
            url: "/reports/ProductWiseAvailabilityReport",
          },
          {
            id: "Product Availability Time",
            title: <FormattedMessage id="Product Availability Time" />,
            type: "item",
            url: "/reports/product-availability-time",
          },
          {
            id: "Combo Report",
            title: <FormattedMessage id="Combo Report" />,
            type: "item",
            url: "/reports/combo-report",
          },
          {
            id: "Florida product purchase by seller",
            title: <FormattedMessage id="Florida product purchase by seller" />,
            type: "item",
            url: "/reports/floridaproductpurchasebyseller",
          },
        ],
      },
    ]
  }
  else {
    return [
      {
        id: "Reports",
        title: <FormattedMessage id="Reports" />,
        type: "collapse",
        icon: icons["IconClipboardList"],
        children: [
          {
            id: "Seller With Florida Orders",
            title: <FormattedMessage id="Seller With Florida Orders" />,
            type: "item",
            url: "/reports/sellerwithfloridaorders",
          },
          {
            id: "buyumedetails",
            title: <FormattedMessage id="BuyuMe Details" />,
            type: "item",
            url: "/reports/buyumedetails",
          },
          {
            id: "mostsellingproduct",
            title: <FormattedMessage id="Most Selling Product" />,
            type: "item",
            url: "/reports/mostsellingproduct",
          },
          {
            id: "mostsellingbrands",
            title: <FormattedMessage id="Most Selling Brands" />,
            type: "item",
            url: "/reports/mostsellingbrands",
          },
          {
            id: "BrandWiseRetailReport",
            title: <FormattedMessage id="Brand Wise Retail Report" />,
            type: "item",
            url: "/reports/BrandWiseRetailReport",
          },
          {
            id: "productorderdetails",
            title: <FormattedMessage id="Product Orders Details" />,
            type: "item",
            url: "/reports/productorderdetails",
          },
          {
            id: "referreport",
            title: <FormattedMessage id="Refer Report" />,
            type: "item",
            url: "/reports/referreport",
          },
          {
            id: "downloadreports",
            title: <FormattedMessage id="Download Report" />,
            type: "item",
            url: "/reports/downloadreports",
          },
          {
            id: "OrderDateCohort Online ",
            title: <FormattedMessage id="OrderDateCohort Online " />,
            type: "item",
            url: "/reports/OrderDateCohort",
          },
          {
            id: "OrderDateCohort Online with RTO",
            title: <FormattedMessage id="OrderDateCohort Online with RTO" />,
            type: "item",
            url: "/reports/OrderDateCohortRto",
          },
          {
            id: "OrderDateCohort Online(South Team)",
            title: <FormattedMessage id="OrderDateCohort Online(South Team)" />,
            type: "item",
            url: "/reports/OrderDateCohortSouth",
          },
          {
            id: "OrderDateCohort Online with RTO(South Team)",
            title: <FormattedMessage id="OrderDateCohort Online with RTO(South Team)" />,
            type: "item",
            url: "/reports/OrderDateCohortRtoSouth",
          },
          {
            id: "OrderDateCohort Offline",
            title: <FormattedMessage id="OrderDateCohort Offline" />,
            type: "item",
            url: "/reports/OrderDateOfflineCohort",
          },
          {
            id: "OrderDateCohort Offline with RTO",
            title: <FormattedMessage id="OrderDateCohort Offline with RTO" />,
            type: "item",
            url: "/reports/OrderDateOfflineCohortRto",
          },
          {
            id: "OrderDateCohort Bulk",
            title: <FormattedMessage id="OrderDateCohort Bulk" />,
            type: "item",
            url: "/reports/OrderDateBulkCohort",
          },
          {
            id: "DownloadcohortReport",
            title: <FormattedMessage id="DownLoad Date Cohort" />,
            type: "item",
            url: "/reports/DownloadDateCohort",
          },
          {
            id: "CacReport",
            title: <FormattedMessage id="Cac Report" />,
            type: "item",
            url: "/reports/CacReport",
          },
          {
            id: "NotAvailableProductsReport",
            title: <FormattedMessage id="Not Available Products Report" />,
            type: "item",
            url: "/reports/notavailableproductsreport",
          },
          {
            id: "ProductDiscountWiseReport",
            title: <FormattedMessage id="Product Discount Wise Report" />,
            type: "item",
            url: "/reports/ProductDiscountWiseReport",
          },
          {
            id: "MembershipReport",
            title: <FormattedMessage id="Membership Report" />,
            type: "item",
            url: "/reports/MembershipReport",
          },
          {
            id: "Expiring Refs Report",
            title: <FormattedMessage id="Expiring Refs Report" />,
            type: "item",
            url: "/reports/expiringRefsReport",
          },
          // {
          //   id: "CallerwiseSalesReport",
          //   title: <FormattedMessage id="Callerwise Sales Report" />,
          //   type: "item",
          //   url: "/reports/CallerwiseSalesReport",
          // },

          {
            id: "SellerGST",
            title: <FormattedMessage id="Seller GST List" />,
            type: "item",
            url: "/reports/sellergst",
          },
          {
            id: "monrhRTO",
            title: <FormattedMessage id="Month RTO Report" />,
            type: "item",
            url: "/reports/rto-report",
          },
          {
            id: "brandOrderPerPerson",
            title: <FormattedMessage id="Brands Order Per Seller" />,
            type: "item",
            url: "/reports/brandOrderPerPerson",
          },
          // {
          //   id: "organicInorginc",
          //   title: <FormattedMessage id="Organic &amp; Inorganic Report" />,
          //   type: "item",
          //   url: "/reports/organicInorganicReport",
          // },
          // {
          //   id: "agentOrderReport",
          //   title: <FormattedMessage id="Agent Order Report" />,
          //   type: "item",
          //   url: "/reports/agentOrderReport",
          // },
          {
            id: "sellerRTOReport",
            title: <FormattedMessage id="Seller Wise RTO Report" />,
            type: "item",
            url: "/reports/sellerWiseRTOReport",
          },
          {
            id: "distributorReport",
            title: <FormattedMessage id="Distributor Report" />,
            type: "item",
            url: "/reports/distributorReport",
          },
          // {
          //   id: "offlineSeller",
          //   title: <FormattedMessage id="Offline Agent Report" />,
          //   type: "item",
          //   url: "/reports/offlineAgentReport",
          // },
          {
            id: "BuyumeOrderWiseReport",
            title: <FormattedMessage id="Buyume OrderWise Report" />,
            type: "item",
            url: "/reports/BuyumeOrderWiseReport",
          },
          {
            id: "Forcast Inventory Report",
            title: <FormattedMessage id="Forcast Inventory Report" />,
            type: "item",
            url: "/reports/forcast-inventoryreport",
          },
          {
            id: "Product Wise Availability Report",
            title: <FormattedMessage id="Product Wise Availability Report" />,
            type: "item",
            url: "/reports/ProductWiseAvailabilityReport",
          },
          {
            id: "Product Availability Time",
            title: <FormattedMessage id="Product Availability Time" />,
            type: "item",
            url: "/reports/product-availability-time",
          },
          {
            id: "Combo Report",
            title: <FormattedMessage id="Combo Report" />,
            type: "item",
            url: "/reports/combo-report",
          },
          {
            id: "Florida product purchase by seller",
            title: <FormattedMessage id="Florida product purchase by seller" />,
            type: "item",
            url: "/reports/floridaproductpurchasebyseller",
          },

        ],
      },

    ]
  }
}

export const Reports = {
  id: "AllReports",
  title: <FormattedMessage id="All Reports" />,
  type: "group",
  children: viewAsPerRole()
};
