import { useEffect, useState } from 'react';
import menuItem from '../menu-items'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';


export default function NavListSearch() {
    const [arr, setArr] = useState([])


    const filterUrlObj = (Arr) => {
        let temp = Arr.filter((item) => {
            return item?.url
        })
        setArr([...arr, ...temp])
        return temp;
    }

    const filterChildrenArr = (Arr) => {
        let temp = Arr.filter((item) => {
            return !item?.url
        })
        return temp;
    }

    const getChildrens = (Arr) => {
        let temp = []
        Arr.map((item) => {
            if (item?.children) {
                temp = [...temp, ...item?.children]
            }
        })
        return temp
    }

    useEffect(() => {
        let arr = [...menuItem.items]
        let childs = filterChildrenArr(arr)
        let children = getChildrens(childs)
        let childs2 = filterChildrenArr(children)
        let finalArr = filterUrlObj(getChildrens(childs2))
        setArr(finalArr)
    }, [])

    return (
        <>
            <Stack spacing={2} sx={{ width: 230, marginLeft: "16px" }}>
                <Autocomplete
                    id="free-solo-2-demo"
                    disableClearable
                    getOptionLabel={(option) => `${option?.id}(${option?.url})`}
                    options={arr.map((option) => option)}
                    onChange={(event, option) => {
                        window.location.href = `/crm${option.url}`
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search input"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )}
                />
            </Stack>
        </>
    )
}
