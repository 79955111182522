export const calculateDiscount = (product, membershipExist) => {
    // console.log("membershipExist", membershipExist)
    if (product.lightningDiscount) {
        return product.baseDiscount + product.flashSaleDiscount + product.lightningDiscount
    } else if (product.totalProductDiscount) {
        return product.totalProductDiscount;
    } else {
        product.clubDiscount = membershipExist || product?.clubdiscount || product?.clubDiscount;
        // console.log(product.clubDiscount, "product.clubDiscount")
        if (membershipExist) {
             
            if (product.flashSaleDiscount <= product.clubDiscount) {
                return product.baseDiscount + product.clubDiscount
            } else {
                return product.baseDiscount + product.flashSaleDiscount;
            }
        } else {
            return product.baseDiscount + product.flashSaleDiscount;
        }
    }
};