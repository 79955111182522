import React, { useEffect, useState } from "react";
import SubCard from "../../../ui-component/cards/SubCard";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { th } from "date-fns/locale";
import moment from "moment";

function BlockPincode() {
  const [allPincodeData, setAllPincodeData] = useState([]);
  const [searchPincodes, setSearchPincodes] = useState("");
  const getBlockPincode = () => {
    axios
      .get(process.env.REACT_APP_PATH + "/role1/getAllBlockedPincodes")
      .then((res) => {
        if (res.data.success) {
          setAllPincodeData(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error of block pincode", error);
      });
  };
  const enablePincode = (pinId) => {
    axios
      .post(process.env.REACT_APP_PATH + "/role1/unblockPincode", {
        pincodeId: pinId,
      })
      .then((res) => {
        if (res.data.success) {
          getBlockPincode();
        }
      })
      .catch((error) => alert("Something went wrong"));
  };

  useEffect(() => {
    getBlockPincode();
  }, []);
  return (
    <SubCard>
      <TextField
        value={searchPincodes}
        placeholder="Search Pincode"
        onChange={(e) => setSearchPincodes(e.target.value)}
      />
      <TableContainer  style={{ maxHeight: 420 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
         
        >
          <TableHead>
            <TableRow>
              <TableCell>Pincode</TableCell>
              <TableCell>Enable For</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPincodeData &&
              allPincodeData.length > 0 &&
              allPincodeData
                .filter((item) =>
                  searchPincodes ? item.pincode.includes(searchPincodes) : true
                )
                .map((pin) => (
                  <TableRow>
                    <TableCell>{pin?.pincode}</TableCell>
                    <TableCell>
                      {moment(pin?.enableFor).format("DD-MM-YYYY hh:MM:ss a")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        disabled={pin?.blocked == false}
                        onClick={() => enablePincode(pin?._id)}
                      >
                        Enable
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SubCard>
  );
}

export default BlockPincode;
