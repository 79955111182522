import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
} from "@tabler/icons";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconClipboardList: IconClipboardList,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//

export const AdminReports = {
  id: "Admin Reports",
  title: <FormattedMessage id="Admin Reports" />,
  type: "group",
  children: [
    {
      id: "Admin Report",
      title: <FormattedMessage id="Admin Report" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "buyumedetails",
          title: <FormattedMessage id="BuyuMe Details" />,
          type: "item",
          url: "/admin/reports/buyumedetails",
        },
        {
          id: "mostsellingproduct",
          title: <FormattedMessage id="Most Selling Product" />,
          type: "item",
          url: "/admin/reports/mostsellingproduct",
        },
        {
          id: "mostsellingbrands",
          title: <FormattedMessage id="Most Selling Brands" />,
          type: "item",
          url: "/admin/reports/mostsellingbrands",
        },
        {
          id: "productorderdetails",
          title: <FormattedMessage id="Product Orders Details" />,
          type: "item",
          url: "/admin/reports/productorderdetails",
        },
        {
          id: "referreport",
          title: <FormattedMessage id="Refer Report" />,
          type: "item",
          url: "/admin/reports/referreport",
        },
        {
          id: "downloadreports",
          title: <FormattedMessage id="Download Report" />,
          type: "item",
          url: "/admin/reports/downloadreports",
        },
        {
          id: "DownloadcohortReport",
          title: <FormattedMessage id="Order Date Cohort" />,
          type: "item",
          url: "/admin/reports/OrderDateCohort",
        },
        {
          id: "Order Date Cohort(With RTO)",
          title: <FormattedMessage id="Order Date Cohort(With RTO)" />,
          type: "item",
          url: "/admin/reports/order-date-cohort-rto",
        },
        // {
        //   id: "cohortReport",
        //   title: <FormattedMessage id="Cohort Report" />,
        //   type: "item",
        //   url: "/admin/reports/cohortreport",
        // },
        {
          id: "NotAvailableProductsReport",
          title: <FormattedMessage id="Not Available Products Report" />,
          type: "item",
          url: "/admin/reports/notavailableproductsreport",
        },
        {
          id: "SellerWiseRevenu",
          title: <FormattedMessage id="Seller Wise Revenue" />,
          type: "item",
          url: "/admin/reports/SellerWiseRevenu",
        },
        {
          id: "CategoryWiseReport",
          title: <FormattedMessage id="Category Wise Revenue" />,
          type: "item",
          url: "/admin/reports/CategoryWiseReport",
        },
        {
          id: "StateWiseSalesReport",
          title: <FormattedMessage id="State Wise Sales Report" />,
          type: "item",
          url: "/admin/reports/StateWiseSalesReport",
        },
        {
          id: "BrandCohortReport",
          title: <FormattedMessage id="Brand Cohort Report" />,
          type: "item",
          url: "/admin/reports/BrandCohortReport",
        },
        {
          id: "SellerRtoReport",
          title: <FormattedMessage id="Seller RTO report" />,
          type: "item",
          url: "/admin/reports/SellerRtoReports",
        },
        {
          id: "SellerCoinReport",
          title: <FormattedMessage id="Seller Coin report" />,
          type: "item",
          url: "/admin/reports/SellerCoinReport",
        },

        {
          id: "ReferalAmount",
          title: <FormattedMessage id="Referal Amount Month Wise" />,
          type: "item",
          url: "/admin/reports/referalAmount",
        },
        // {
        //   id: "BuyumeReportOrderWise",
        //   title: <FormattedMessage id="Buyume Report Order Wise" />,
        //   type: "item",
        //   url: "/admin/reports/BuyumeReportOrderWise",
        // },
        {
          id: "Shipped Order Report ",
          title: <FormattedMessage id="Shipped Order Report " />,
          type: "item",
          url: "/admin/reports/shippedOrders",
        },


      ],
    },
  ],
};
