import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { LoadingButton } from "@material-ui/lab";
import { CSVLink } from "react-csv";


const bulkChildOrderHeader  = [
  {label: "Parent Order Id", key: 'parentBulkNumericOrderId'},
  {label: "Child Order Id", key: 'numericOrderId'},
  {label: "Invoice No.", key: 'zohoInvoice.zohoInvoiceNumber'},
  {label: "AWB Number", key: 'awbNumber'},
  {label: "Created At", key: 'createdAt'},
  {label: "Pushed to sales order", key: 'pushedToProductOrder'},
  {label: "Total payable amount", key: 'totalPayableAmount'},
  
]


const useStyles = makeStyles((theme) => ({

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
function BulkShipStatus() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const agentType = localStorage.getItem("setRole");
  const [date, setDate] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: new Date().toISOString().slice(0, 10)
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [orderdata, setOrderData] = useState([]);
  const [pageLoad, togglePageLoad] = useState(false);
  const [awbNumberModal, setAwbNumberModal] = useState({
    orderId: "",
    isOpen: false,
  });
  const [awbNumber, setAwbNumber] = useState("");
  const [downloadBulkData,setDownBulkData] = useState([])
  const [loading, setLoading] = useState(false)

  const dataLoad = async () => {
    setLoading(true)
    let temp =  []
    axios
      .post(process.env.REACT_APP_PATH + "/sales/getBulkOrders", {
        status: 2,
        startDate:date.startDate,
        endDate:date.endDate,
        stage:agentType==5?3:undefined
      })
      .then((res) => {
        if (res.data.success) {
          setOrderData(res.data.data[0].data || []);
          togglePageLoad(true);
          res.data.data[0].data && res.data.data[0].data.forEach(element => {
              element.childOrders&& element.childOrders.forEach((item)=>{
                temp.push(item)
              })
          });
          setDownBulkData(temp) 
          setLoading(false)
        }

      })
      .catch((error) => {
        console.log("error of get data", error);
        setLoading(false)
      });
  };

  const updateAwbNumber = () => {
    axios
      .post(
        process.env.REACT_APP_PATH + "/products/addAwbNumberInBulkOrder",
        {
          orderId: awbNumberModal.orderId,
          awbNumber: awbNumber,
        }
      )
      .then((res) => {
        console.log("res of updateCnDiscountPercentage bill", res.data);
        if (res.data.success) {
          alert("Update successfully");
          dataLoad(page, rowsPerPage);
          setAwbNumberModal({
            isOpen: false,
            orderId: "",
          });
          setAwbNumber('')
        } else {
          alert(res.data?.message || "something went wrong");
        }
      })
      .catch((error) => {
        console.log("error of updateCnDiscountPercentage ", error);
        alert("something went wrong");
      });
  };

  useEffect(() => {
    dataLoad(page, rowsPerPage);
  }, []);

  if (!pageLoad) {
    return (
      <div>
        <h1>Loading</h1>
      </div>
    );
  }

  const chip = (status) => {
    if (status === 1) {
      return <Chip label="Processing" size="small" chipcolor="primary" />;
    } else if (status === 2) {
      return <Chip label="Canceled" size="small" chipcolor="orange" />;
    } else if (status === 3) {
      return <Chip label="Complete" size="small" chipcolor="success" />;
    } else if (status === 4) {
      return <Chip label="RTO" size="small" chipcolor="orange" />;
    } else if (status === 6) {
      return <Chip label="Undelivered" size="small" chipcolor="orange" />;
    } else if (status == 8) {
      return <Chip label="Splitted" size="small" chipcolor="secondary" />;
    }
  };

  function CollapesRow({ order }) {
    const [open, setOpen] = React.useState(false);
    console.log("order", order);

    return (
      <React.Fragment>
       
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{order?.numericOrderId}</TableCell>
          <TableCell>
            {moment(order?.createdAt).format("hh:mm A DD-MM-YYYY")}
          </TableCell>
          <TableCell>{order?.name}</TableCell>
          <TableCell>{order?.phoneNumber}</TableCell>
          <TableCell>{order?.totalPayableAmount}</TableCell>
          <TableCell>
            <Link
              to={`/bulkorderdetails/${order._id}`}
              style={{
                textDecoration: "none",
                color: order.paymentMethod == 1 ? "#000" : "green",
              }}
              target="_blank"
            >
              Details
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Invoice No.</TableCell>
                    <TableCell>Invoice Url</TableCell>
                    <TableCell>AWB Number</TableCell>
                    <TableCell>CN Numbers</TableCell>
                    <TableCell>Tracking Status</TableCell>
                    <TableCell>Rocket Box Label</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order &&
                    order.childOrders.length > 0 &&
                    order.childOrders.map((product, index) => (
                      <TableRow>
                        <TableCell>Order {index + 1}</TableCell>
                        <TableCell>{product?.numericOrderId}</TableCell>
                        <TableCell>
                          {product?.zohoInvoice?.zohoInvoiceNumber}
                        </TableCell>

                        <TableCell>
                          {product?.zohoInvoice?.invoiceUrl ? (
                            <a
                              href={product?.zohoInvoice?.invoiceUrl}
                              target="_blank"
                            >
                              <p>Open</p>
                            </a>
                          ) : (
                            <p>Not Available</p>
                          )}
                        </TableCell>
                        <TableCell>{product?.awbNumber}</TableCell>
                        <TableCell>{product?.zohoInvoice?.creditNoteNumber}</TableCell>
                        <TableCell>
                          {product.status === 3 && chip(3)}
                          {product.status === 2 && chip(2)}
                          {product.status === 4 && chip(4)}
                          {product.status === 6 && chip(6)}
                          {product.status === 8 && chip(8)}
                          {product.awbNumber && product.status == 1 ? (
                            <Chip
                              label="In Transit"
                              size="small"
                              chipcolor="primary"
                            />
                          ) : product.status === 1 && !product.awbNumber ? (
                            chip(1)
                          ) : null}
                        </TableCell>

                        <TableCell>
                          <a href={product?.rocketBoxInvoiceUrl} target="_blank">
                            <p>Open</p>
                          </a>{" "}
                        </TableCell>
                        <TableCell>
                          {
                            agentType!=5&&
                          
                         <Button onClick={()=>{
                          setAwbNumberModal({
                            isOpen:true,
                            orderId:product._id
                          })
                         }}
                         variant='contained'
                         > Update Awb</Button> 
                        }
                          </TableCell>
                        <TableCell>
                          <Link
                            to={`/bulkorderdetails/${product._id}`}
                            style={{
                              textDecoration: "none",
                              color:
                                order.paymentMethod == 1 ? "#000" : "green",
                            }}
                            target="_blank"
                          >
                            Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <SubCard title={"Bulk Ship Order"}>
       <Grid container spacing={2} alignItems={'center'}>
        <Grid xs={3} item>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
          />

        </Grid>
        <Grid item xs={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            variant="contained"
            style={{
              width: "100%",
            }}
            loadingIndicator="loading..."
            onClick={() => dataLoad()}
            loading={loading}
          >Submit
          </LoadingButton>
        </Grid>
        {
          agentType!=5 &&  <Grid item lg={3} md={3} sm={12}>
          <CSVLink filename="Bulkship.csv" headers={bulkChildOrderHeader} data={downloadBulkData} title="Download data"  style={{ textDecoration: 'none', border: '1px solid #999', padding: '10px 20px', backgroundColor: '#95c7f1' }} >Download data</CSVLink>
    </Grid>
        }
       
        </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Order Id</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Amount</TableCell>

              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderdata &&
              orderdata.map((product) => <CollapesRow order={product} />)}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[25, 200]}
        component="div"
        count={totalpage}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      <Dialog
        open={awbNumberModal.isOpen}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            <Grid container>
              <Grid item lg={6}>
                <Typography variant="h3">Update Awb Number</Typography>
              </Grid>
              <Grid item lg={6} justifyContent={"flex-end"} display={"flex"}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setAwbNumberModal({
                      isOpen: false,
                      orderId: "",
                    })
                  }
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid container>
            <Grid item lg={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={8} md={8} sm={12}>
              <DialogContentText id="alert-dialog-description">
                <TextField
                  type="text"
                  name="awbNumber"
                  value={awbNumber}
                  onChange={(e) => setAwbNumber(e.target.value)}
                  placeholder="Enter Awb Number"
                  fullWidth
                  required
                />
              </DialogContentText>
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => updateAwbNumber()}
                >
                  Update
                </Button>
              </DialogActions>
            </Grid>
           
          </Grid>
        </DialogContent>
      </Dialog>
    </SubCard>
  );
}

export default BulkShipStatus;
