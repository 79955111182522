import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";

function FcGrnPost() {
  const [modalOpen, setModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [inventoryUpdated, setInventoryUpdated] = useState(false);

  const getData = () => {
    setLoading(true);
    setData([]);
    axios
      .get(
        `${process.env.REACT_APP_PATH}/fulfilment/purchaseOrder/getFcPurchaseOrder?inventoryUpdated=${inventoryUpdated}`
      )
      .then((res) => {
        if (res?.data?.headers?.success) {
          setData(res?.data?.body || []);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [inventoryUpdated]);

  return (
    <SubCard title={"Fc Grn"}>
      <Grid container>
        <Grid item xs={12} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-name-label">Select Status</InputLabel>
            <Select
              value={inventoryUpdated}
              label="Select Status"
              onChange={(e) => setInventoryUpdated(e.target.value)}
              required
            >
              <MenuItem value={false}>Pending</MenuItem>
              <MenuItem value={true}>Delivered</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Po Number</TableCell>
            <TableCell>DMG/Missing quantity</TableCell>
            <TableCell>MRP</TableCell>
            <TableCell>Payment Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell onClick={() => setModalOpen(true)}>Po Number</TableCell>
            <TableCell>DMG/Missing quantity</TableCell>
            <TableCell>MRP</TableCell>
            <TableCell>Payment Status</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Mrp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Mrp</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </SubCard>
  );
}

export default FcGrnPost;
