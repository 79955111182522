import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconUser,
} from "@tabler/icons";
const role = localStorage.getItem("setRole")
const agentType = localStorage.getItem('agentType')
const phoneNumber = localStorage.getItem('phoneNumber')
const sellerProfileAccessNumbers = ["9999957559", "9870176766", "9625267398", "8076390508", "8595042994"]
// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconUser: IconUser,
};

//-----------------------|| Seller MENU ITEMS ||-----------------------//

var sellerList = []


if (role == 1 || role == 2 || role == 17) {
  if(Number(phoneNumber) === 9719836187){
    sellerList = [{
      id: "sellerlist",
      title: <FormattedMessage id="Seller List" />,
      type: "item",
      url: "/sellerlist",
    },
    {
      id: "Offline Sellers",
      title: <FormattedMessage id="Offline Sellers" />,
      type: "item",
      url: "/seller/OfflineSellers",
    },
    {
      id: "SellerAddressList",
      title: <FormattedMessage id="Seller Address List" />,
      type: "item",
      url: "/seller-address-list",
    },
    {
      id: "sellerpayouts",
      title: <FormattedMessage id="Seller Payouts" />,
      type: "item",
      url: "/allseller/sellerpayouts",
    },
    // {
    //   id: "sellerapprovelist",
    //   title: <FormattedMessage id="Seller Approve List" />,
    //   type: "item",
    //   url: "/allseller/sellerapprovelist",
    // },
    {
      id: "sellerbillapprovelist",
      title: <FormattedMessage id="Seller Bill Approve List" />,
      type: "item",
      url: "/allseller/sellerbillapprovelist",
    },
    {
      id: "customercartproduct",
      title: <FormattedMessage id="Customer Cart Product" />,
      type: "item",
      url: "/seller/customercartproduct",
    },
    {
      id: "Organic Sellers",
      title: <FormattedMessage id="Organic Sellers" />,
      type: "item",
      url: "/seller/OrganicSellers",
    },
    {
      id: "SellerMembership",
      title: <FormattedMessage id="Seller Approve List" />,
      type: "item",
      url: "/seller/sellerMembership",
    },
    {
      id: "SearchBusinessName",
      title: <FormattedMessage id="Search Business Name" />,
      type: "item",
      url: "/search-business-name",
    },
    {
      id: "Seller Profile Verification",
      title: <FormattedMessage id="Seller Profile Verification" />,
      type: "item",
      url: "/seller-profile-report",
    },
    {
      id: "Gift Seller Coupon",
      title: <FormattedMessage id="Gift Seller Coupon" />,
      type: "item",
      url: "/giftsellercoupon",
    },
    ]
  }else{
    sellerList = [{
      id: "sellerlist",
      title: <FormattedMessage id="Seller List" />,
      type: "item",
      url: "/sellerlist",
    },
    {
      id: "Offline Sellers",
      title: <FormattedMessage id="Offline Sellers" />,
      type: "item",
      url: "/seller/OfflineSellers",
    },
    {
      id: "SellerAddressList",
      title: <FormattedMessage id="Seller Address List" />,
      type: "item",
      url: "/seller-address-list",
    },
    {
      id: "sellerpayouts",
      title: <FormattedMessage id="Seller Payouts" />,
      type: "item",
      url: "/allseller/sellerpayouts",
    },
    // {
    //   id: "sellerapprovelist",
    //   title: <FormattedMessage id="Seller Approve List" />,
    //   type: "item",
    //   url: "/allseller/sellerapprovelist",
    // },
    {
      id: "sellerbillapprovelist",
      title: <FormattedMessage id="Seller Bill Approve List" />,
      type: "item",
      url: "/allseller/sellerbillapprovelist",
    },
    {
      id: "customercartproduct",
      title: <FormattedMessage id="Customer Cart Product" />,
      type: "item",
      url: "/seller/customercartproduct",
    },
    {
      id: "Organic Sellers",
      title: <FormattedMessage id="Organic Sellers" />,
      type: "item",
      url: "/seller/OrganicSellers",
    },
    {
      id: "SellerMembership",
      title: <FormattedMessage id="Seller Approve List" />,
      type: "item",
      url: "/seller/sellerMembership",
    },
    {
      id: "SearchBusinessName",
      title: <FormattedMessage id="Search Business Name" />,
      type: "item",
      url: "/search-business-name",
    },
    {
      id: "Seller Profile Verification",
      title: <FormattedMessage id="Seller Profile Verification" />,
      type: "item",
      url: "/seller-profile-report",
    },
    ]
  }
} else if (role == 13 || role == 14) {
  sellerList =
    [
      {
        id: "sellerlist2",
        title: <FormattedMessage id="Seller List" />,
        type: "item",
        url: "/sellerlist",
      },
      {
        id: "Offline Sellers",
        title: <FormattedMessage id="Offline Sellers" />,
        type: "item",
        url: "/seller/OfflineSellers",
      },
      {
        id: "SellerAddressList",
        title: <FormattedMessage id="Seller Address List" />,
        type: "item",
        url: "/seller-address-list",
      },
      {
        id: "sellerpayouts2",
        title: <FormattedMessage id="Seller Payouts" />,
        type: "item",
        url: "/allseller/sellerpayouts",
      },
      {
        id: "customercartproduct2",
        title: <FormattedMessage id="Customer Cart Product" />,
        type: "item",
        url: "/seller/customercartproduct",
      },
      // {
      //   id: "sellerapprovelist",
      //   title: <FormattedMessage id="Seller Approve List" />,
      //   type: "item",
      //   url: "/allseller/sellerapprovelist",
      // },
      {
        id: "sellerbillapprovelist",
        title: <FormattedMessage id="Seller Bill Approve List" />,
        type: "item",
        url: "/allseller/sellerbillapprovelist",
      },
      {
        id: "SellerMembership",
        title: <FormattedMessage id="Seller Approve List" />,
        type: "item",
        url: "/seller/sellerMembership",
      },



    ]
} else if (role == 4 || role == 17) {
  if (Number(phoneNumber) === 8882307830) {
    sellerList =
      [
        {
          id: "sellerlist2",
          title: <FormattedMessage id="Seller List" />,
          type: "item",
          url: "/sellerlist",
        },
        {
          id: "Offline Sellers",
          title: <FormattedMessage id="Offline Sellers" />,
          type: "item",
          url: "/seller/OfflineSellers",
        },
        {
          id: "SellerAddressList",
          title: <FormattedMessage id="Seller Address List" />,
          type: "item",
          url: "/seller-address-list",
        },
        {
          id: "sellerpayouts2",
          title: <FormattedMessage id="Seller Payouts" />,
          type: "item",
          url: "/allseller/sellerpayouts",
        },
        // {
        //   id: "sellerapprovelist",
        //   title: <FormattedMessage id="Seller Approve List" />,
        //   type: "item",
        //   url: "/allseller/sellerapprovelist",
        // },
        {
          id: "customercartproduct2",
          title: <FormattedMessage id="Customer Cart Product" />,
          type: "item",
          url: "/seller/customercartproduct",
        },
        {
          id: "sellerbillapprovelist",
          title: <FormattedMessage id="Seller Bill Approve List" />,
          type: "item",
          url: "/allseller/sellerbillapprovelist",
        },
        {
          id: "Organic Sellers",
          title: <FormattedMessage id="Organic Sellers" />,
          type: "item",
          url: "/seller/OrganicSellers",
        },
        {
          id: "SellerMembership",
          title: <FormattedMessage id="Seller Approve List" />,
          type: "item",
          url: "/seller/sellerMembership",
        },
        {
          id: "SearchBusinessName",
          title: <FormattedMessage id="Search Business Name" />,
          type: "item",
          url: "/search-business-name",
        },
        {
          id: "Seller Profile Verification",
          title: <FormattedMessage id="Seller Profile Verification" />,
          type: "item",
          url: "/seller-profile-report",
        },
        {
          id: "Gift Seller Coupon",
          title: <FormattedMessage id="Gift Seller Coupon" />,
          type: "item",
          url: "/giftsellercoupon",
        },
      ]
  }else if (Number(phoneNumber) === 9625342361) {
    sellerList =
      [
        {
          id: "sellerlist2",
          title: <FormattedMessage id="Seller List" />,
          type: "item",
          url: "/sellerlist",
        },
        {
          id: "Offline Sellers",
          title: <FormattedMessage id="Offline Sellers" />,
          type: "item",
          url: "/seller/OfflineSellers",
        },
        {
          id: "SellerAddressList",
          title: <FormattedMessage id="Seller Address List" />,
          type: "item",
          url: "/seller-address-list",
        },
        {
          id: "sellerpayouts2",
          title: <FormattedMessage id="Seller Payouts" />,
          type: "item",
          url: "/allseller/sellerpayouts",
        },
        // {
        //   id: "sellerapprovelist",
        //   title: <FormattedMessage id="Seller Approve List" />,
        //   type: "item",
        //   url: "/allseller/sellerapprovelist",
        // },
        {
          id: "customercartproduct2",
          title: <FormattedMessage id="Customer Cart Product" />,
          type: "item",
          url: "/seller/customercartproduct",
        },
        {
          id: "sellerbillapprovelist",
          title: <FormattedMessage id="Seller Bill Approve List" />,
          type: "item",
          url: "/allseller/sellerbillapprovelist",
        },
        {
          id: "Organic Sellers",
          title: <FormattedMessage id="Organic Sellers" />,
          type: "item",
          url: "/seller/OrganicSellers",
        },
        {
          id: "SellerMembership",
          title: <FormattedMessage id="Seller Approve List" />,
          type: "item",
          url: "/seller/sellerMembership",
        },
        {
          id: "SearchBusinessName",
          title: <FormattedMessage id="Search Business Name" />,
          type: "item",
          url: "/search-business-name",
        },
        {
          id: "Seller Profile Verification",
          title: <FormattedMessage id="Seller Profile Verification" />,
          type: "item",
          url: "/seller-profile-report",
        },
      ]
  } else {
    sellerList =
      [
        {
          id: "sellerlist2",
          title: <FormattedMessage id="Seller List" />,
          type: "item",
          url: "/sellerlist",
        },
        {
          id: "Offline Sellers",
          title: <FormattedMessage id="Offline Sellers" />,
          type: "item",
          url: "/seller/OfflineSellers",
        },
        {
          id: "SellerAddressList",
          title: <FormattedMessage id="Seller Address List" />,
          type: "item",
          url: "/seller-address-list",
        },
        {
          id: "sellerpayouts2",
          title: <FormattedMessage id="Seller Payouts" />,
          type: "item",
          url: "/allseller/sellerpayouts",
        },
        {
          id: "customercartproduct2",
          title: <FormattedMessage id="Customer Cart Product" />,
          type: "item",
          url: "/seller/customercartproduct",
        },
        {
          id: "sellerbillapprovelist",
          title: <FormattedMessage id="Seller Bill Approve List" />,
          type: "item",
          url: "/allseller/sellerbillapprovelist",
        },
        {
          id: "Organic Sellers",
          title: <FormattedMessage id="Organic Sellers" />,
          type: "item",
          url: "/seller/OrganicSellers",
        },
        {
          id: "SearchBusinessName",
          title: <FormattedMessage id="Search Business Name" />,
          type: "item",
          url: "/search-business-name",
        },
        {
          id: "Seller Profile Verification",
          title: <FormattedMessage id="Seller Profile Verification" />,
          type: "item",
          url: "/seller-profile-report",
        },
      ]
  }
} else if (role == 5) {
  if (agentType === "ONLINE" || agentType === "BULK") {
    sellerList =
      [{
        id: "sellerlist2",
        title: <FormattedMessage id="Seller List" />,
        type: "item",
        url: "/sellerlist",
      },
      {
        id: "SearchBusinessName",
        title: <FormattedMessage id="Search Business Name" />,
        type: "item",
        url: "/search-business-name",
      },
      ]
  } else if (agentType === "OFFLINE") {
    sellerList = [{
      id: "Offline Sellers",
      title: <FormattedMessage id="Offline Sellers" />,
      type: "item",
      url: "/seller/OfflineSellers",
    },
    {
      id: "SearchBusinessName",
      title: <FormattedMessage id="Search Business Name" />,
      type: "item",
      url: "/search-business-name",
    },
    ]
  }
  if (sellerProfileAccessNumbers.includes(phoneNumber)) {
    sellerList.push(
      {
        id: "Seller Profile Verification",
        title: <FormattedMessage id="Seller Profile Verification" />,
        type: "item",
        url: "/seller-profile-report",
      }
    )
  }
} else {
  sellerList =
    [
      {
        id: "sellerlist2",
        title: <FormattedMessage id="Seller List" />,
        type: "item",
        url: "/sellerlist",
      },
      {
        id: "Offline Sellers",
        title: <FormattedMessage id="Offline Sellers" />,
        type: "item",
        url: "/seller/OfflineSellers",
      },
      {
        id: "SellerAddressList",
        title: <FormattedMessage id="Seller Address List" />,
        type: "item",
        url: "/seller-address-list",
      },
      {
        id: "sellerpayouts2",
        title: <FormattedMessage id="Seller Payouts" />,
        type: "item",
        url: "/allseller/sellerpayouts",
      },
      {
        id: "sellerbillapprovelist",
        title: <FormattedMessage id="Seller Bill Approve List" />,
        type: "item",
        url: "/allseller/sellerbillapprovelist",
      },
      {
        id: "SearchBusinessName",
        title: <FormattedMessage id="Search Business Name" />,
        type: "item",
        url: "/search-business-name",
      },
    ]
}

export const Seller = {
  id: "All Seller",
  title: <FormattedMessage id="All Seller" />,
  type: "group",
  children: [
    {
      id: "Seller3",
      title: <FormattedMessage id="Seller" />,
      type: "collapse",
      icon: icons["IconUser"],
      children: sellerList,
    },
  ],
};
