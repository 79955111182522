import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
// import RTLLayout from './ui-component/RTLLayout';
import Snackbar from './ui-component/extended/Snackbar';

// auth provider
// import { FirebaseProvider } from './contexts/FirebaseContext';
import { JWTProvider } from '../src/contexts/JWTContext';
// import {Auth0Provider} from '../contexts/Auth0Context';

//HMS100 Import 
import { HMSRoomProvider } from '@100mslive/react-sdk'

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import config from './config';
// Initialize Firebase
const app = initializeApp(config.firebase);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);
logEvent(analytics, 'prelive_ga_loaded');
//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.customization);





    return (
        <StyledEngineProvider injectFirst>
            <HMSRoomProvider>
                <ThemeProvider theme={theme(customization)}>
                    <CssBaseline />
                    {/* RTL layout */}
                    {/* <RTLLayout> */}
                    <Locales>
                        <NavigationScroll>
                            <JWTProvider>
                                <Routes />
                                <Snackbar />
                            </JWTProvider>
                        </NavigationScroll>
                    </Locales>
                    {/* </RTLLayout> */}
                </ThemeProvider>
            </HMSRoomProvider>
        </StyledEngineProvider>
    );
};

export default App;
