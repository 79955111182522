import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  CardContent,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Select,
  FormControl,
  Button,
  Paper,
  Collapse,
  Box,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Alert,
  DialogActions,
  Divider,
  MenuItem,
} from "@material-ui/core";
import Chip from "../../ui-component/extended/Chip";
// project imports
import MainCard from "../../ui-component/cards/MainCard";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import axios from "axios";
import ApiInstance from "../../Services/ApiInstance";
import moment from "moment";
import Ordershippopup from "./../Vendor/Ordershippopup";
import AlertModal from "./AlertModal";
import { LoadingButton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    maxHeight: 440,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

//-----------------------|| TABLE - STICKY HEADER ||-----------------------//

export default function BulkOrderList() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [date, setDate] = useState({
    startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
    endDate: new Date().toISOString().slice(0, 10)
  });
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [orderdata, setOrderData] = useState([]);
  const [search, setSearch] = React.useState("");
  const [pageLoad, togglePageLoad] = useState(false);
  const [totalpage, setTotalPage] = useState();
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState(null);
  const [bulkOrderFromRocket, setBulkOrderFromRocket] = useState(false);
  const [cnDiscount, setCnDiscount] = useState("");
  const [openTableIndex, setOpenTable] = useState({
    index: null,
    isOpen: false,
  });
  const [cnDiscountModal, setCnDiscountModal] = useState({
    orderId: "",
    modal: false,
  });
  const [shippingModeModal, setShippingModeModal] = useState(false);
  const [orderIdClick, setOrderIdClick] = useState("");

  const [openShipmentPopup, setShipmentPopup] = useState({
    open: false,
    orderId: null,
    orderName: "",
    type: "",
    isrocketOrPorter: false,
    organizations:null
  });

  const [ewayBillModal, setEwayBillModal] = useState({
    orderId: "",
    modal: false,
  });
  const [ewayBillNumber, setEwayBillNumber] = useState("");

  const [orderTable, setOrderTable] = useState([1]);
  const dataLoad = async (p = page, row = rowsPerPage) => {
    setLoading(true)
    axios
      .post(process.env.REACT_APP_PATH + "/sales/getBulkOrders", {
        // agentId: selectedAgents?._id||null,
        status: 1,
        page: p,
        pageLimit: row,
        startDate:date.startDate,
        endDate:date.endDate
      })
      .then((res) => {
        if (res.data.success) {
          setOrderData(res.data.data[0].data || []);
          togglePageLoad(true);
          setTotalPage(res.data.data[0]?.metadata[0]?.totalDocuments);
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log("error of get data", error);
        setLoading(false)
      });
  };

  const updateEwayBillNumber = () => {
    axios
      .post(process.env.REACT_APP_PATH + "/products/updateEwayBillNumber", {
        orderId: ewayBillModal.orderId,
        ewayBillNumber: ewayBillNumber,
      })
      .then((res) => {
        console.log("res of updateEway bill", res.data);
        if (res.data.success) {
          alert("Update successfully");
          dataLoad(page, rowsPerPage);
          setEwayBillModal({
            modal: false,
            orderId: "",
          });
        } else {
          alert(res.data?.message || "something went wrong");
        }
      })
      .catch((error) => {
        console.log("error of eway bill", error);
        alert("something went wrong");
      });
  };

  const updateCnDiscount = () => {
    axios
      .post(
        process.env.REACT_APP_PATH + "/products/updateCnDiscountPercentage",
        {
          orderId: cnDiscountModal.orderId,
          cnDiscountPercentage: cnDiscount,
        }
      )
      .then((res) => {
        console.log("res of updateCnDiscountPercentage bill", res.data);
        if (res.data.success) {
          alert("Update successfully");
          dataLoad(page, rowsPerPage);
          setCnDiscountModal({
            modal: false,
            orderId: "",
          });
        } else {
          alert(res.data?.message || "something went wrong");
        }
      })
      .catch((error) => {
        console.log("error of updateCnDiscountPercentage ", error);
        alert("something went wrong");
      });
  };

  const createOrder = (orderId, index,emergent) => {
    setDisableBtn(true);
    const dataObj = {
      orderId: orderId,
      emergentOrFlorida:emergent
    };
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/products/createBulkSalesOrderOnUc`,
      data: dataObj,
    })
      .then((res) => {
        if (res.data.success) {
          setDisableBtn(false);
          dataLoad(0, 25);
        } else {
          alert(res.data.message);
          setDisableBtn(false);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        setDisableBtn(false);
      });
  };

  useEffect(() => {
    dataLoad(page, rowsPerPage);
  }, []);
  const createPoOnZoho = (orderId) => {
    console.log("order id", orderId);
    ApiInstance.post('/products/createPurchaseOrderAtZoho',{
      orderId:orderId
    }
    ).then((res)=>{
      if(res.data.success){
        alert('Success')
        dataLoad()
      }
    }).catch((error)=>{
      alert('Someting went wrong')
    })
  };


  if (!pageLoad) {
    return (
      <Paper align="center">
        <img
          alt="Example Alt"
          style={{ width: "200px", height: "130px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
          align="center"
        />
      </Paper>
    );
  }

  const handelShippingMode = (orderId) => {
    setShippingModeModal(true);
    setOrderIdClick(orderId);
  };

  function CollapesRow({ order, index }) {
    const [open, setOpen] = React.useState(false);
    const [selectOrganizations,setSelectOrganization] = useState(order?.organization||1)
    const [numOrder, setNumOfOrders] = useState([]);
    const [allProductQnt, setAllProductQnt] = useState([]);
    const [buttonDis, setButtonDis] = useState(false);
    const [orignalProducts, setOrignalProduct] = useState(
      order?.products || []
    );

    const breakProductOrderWise = () => {
      let temp = [...orignalProducts];
      temp.forEach((pro) => {
        let num = new Array(numOrder.length).fill(0)
        pro[`quantity${numOrder.length + 1}`] = numOrder.length==0? pro.quantity:pro.quantity- num.map((item,index)=>pro[`quantity${index+1}`]||0).reduce((acc,item)=>acc+=item,0);
      });
      setOrignalProduct(temp)
    };
    const addOrder = () => {
      // setNumOfOrders((pre) => [pre.length==0?1:]);
      let temp = numOrder;
      if (temp.length == 0) {
        setNumOfOrders([1]);
      } else {
        setNumOfOrders((pre) => [...pre, pre[pre.length - 1] + 1]);
      }
      breakProductOrderWise();
    };
    const removeOrder = (it) => {
      let temp = numOrder.filter((pre, index) => pre != it);
      setNumOfOrders(temp);
    };

    const quantityChangeOfProduct = (productId, keyName, value) => {
      let temp = [...orignalProducts];
      temp.forEach((pro) => {
        if (pro._id == productId) {
          pro[keyName] = +value;
        }
      });
      setOrignalProduct(temp);
    };

    const orderCreateApi = () => {
      setButtonDis(true);
      let pros = [...orignalProducts];
      let nums = [...numOrder];
      let finalProductArray = [];
      nums.forEach((item) => {
        let t = [];
        pros.forEach((pro) => {
          console.log("pro[`quantity${item}`]", pro[`quantity${item}`]);
          if (pro[`quantity${item}`] > 0) {
            t.push({
              ...pro,
              quantity: pro[`quantity${item}`],
            });
          }
        });
        finalProductArray.push(t);
      });

      axios
        .post(process.env.REACT_APP_PATH + "/sales/createAndSplitBulkOrders", {
          orders: finalProductArray,
          mainOrderId: order?._id,
        })
        .then((res) => {
          console.log("res of create order", res.data);
          dataLoad();
          setButtonDis(false);
        })
        .catch((error) => {
          console.log("error of create list", error);
          setButtonDis(false);
        });
    };

    const isDisable = (pro, num) => {
      let temp = [];
      const allProduct = [];
      let allQuant = [];
      const istrue = [];

      pro.forEach((it) => {
        let temp2 = [];
        num.forEach((nu) => {
          temp2.push(it[`quantity${nu}`]);
          allProduct.push();
        });
        temp.push(temp2);
        allProduct.push();
        allQuant.push(it.quantity);
      });
      allQuant.forEach((ite, index) => {
        let sum = 0;
        temp[index].forEach((r) => {
          sum += r;
        });
        if (sum != ite) {
          istrue.push(true);
        } else {
          istrue.push(false);
        }
      });
      return istrue.includes(true);
    };
    const getTotalPaybel = (pro, num, buttonNum) => {
      let temp = [];
      let mrpAndDiscount = [];
      const totalPay = [];
      let sum = 0;

      pro.forEach((it) => {
        let temp2 = [];
        num.forEach((nu) => {
          temp2.push(it[`quantity${nu}`]);
        });
        temp.push(temp2);

        mrpAndDiscount.push({
          mrp: it?.mrp || it?.menuPrice,
          discount: it.discount,
        });
      });

      mrpAndDiscount.forEach((ite, index) => {
        let qnt = temp[index][buttonNum - 1];
        sum += (qnt * ite.mrp * (100 - ite.discount)) / 100;
      });
      return sum;
    };

    // const createPoOnZoho = (orderId) => {
    //   console.log("order id", orderId);
    //   ApiInstance.post('/products/createPurchaseOrderAtZoho',{
    //     orderId:orderId
    //   }
    //   ).then((res)=>{
    //     if(res.data.success){
    //       alert('Success')
    //       dataLoad()
    //     }
    //   }).catch((error)=>{
    //     alert('Someting went wrong')
    //   })
    // };

    return (
      <React.Fragment>
       

        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() =>
                setOpenTable({
                  index: index,
                  isOpen: !openTableIndex.isOpen,
                })
              }
            >
              {openTableIndex.index == index && openTableIndex.isOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell>{order.numericOrderId}</TableCell>
          <TableCell>{order?.name}</TableCell>
          <TableCell>{order?.phoneNumber}</TableCell>
          <TableCell>
            {moment(order.createdAt).format("HH:mm A - DD/MM/YYYY")}
          </TableCell>
          <TableCell>{order?.seller?.gstNumber || "not found"}</TableCell>
          <TableCell>{order?.seller?.businessName || "not found"}</TableCell>
          <TableCell>{order?.totalPayableAmount}</TableCell>
          <TableCell> <Select
            onChange={(v)=>setSelectOrganization(v.target.value)}
            value={selectOrganizations}
            label='Select Organization'
            disabled={order?.organization}
          >
            <MenuItem value={1}>Emergent</MenuItem>
            <MenuItem value={2}>Flordia Beauty</MenuItem>
            </Select> </TableCell>
          {
            <>
              {order?.ucSalesOrderCreatedAt &&
              moment(order?.ucSalesOrderCreatedAt).add(6, "minutes") <=
                moment(new Date()) ? (
                <TableCell>
                  <Button
                    size="small"
                    disabled={disableBtn}
                    variant="outlined"
                    onClick={(e) => {
                      setShipmentPopup({
                        open: true,
                        orderId: order?._id,
                        orderName: "shiprocket",
                        type: "unicommerce",
                      });
                      setBulkOrderFromRocket(false);
                    }}
                  >
                    {order?.awbNumber ? "Already Shiped" : "Ship"}
                  </Button>
                </TableCell>
              ) : (
                <TableCell>
                  <Button
                    size="small"
                    disabled={
                      (disableBtn &&
                        order?.ucSalesOrderCreatedAt &&
                        moment(new Date()) <
                          moment(order?.ucSalesOrderCreatedAt).add(
                            6,
                            "minutes"
                          )) ||
                      order?.childOrders.length == 0
                    }
                    variant="outlined"
                    onClick={(e) => createOrder(order?._id, 1,selectOrganizations)}
                  >
                    {order?.ucSalesOrderCreatedAt &&
                    moment(new Date()) <
                      moment(order?.ucSalesOrderCreatedAt).add(6, "minutes")
                      ? `Wait ${Math.ceil(
                          moment
                            .duration(
                              moment(order?.ucSalesOrderCreatedAt)
                                .add(6, "minutes")
                                .diff(moment(new Date()))
                            )
                            .asMinutes()
                        )} min`
                      : "Create Order"}
                  </Button>
                </TableCell>
              )}
            </>
          }
          {order.awbNumber && order.orderInventoryUpdatedAtZoho==false && (
            <TableCell>
              <Grid style={{ marginTop: 10 }}>
                <Button
                  onClick={() => createPoOnZoho(order._id)}
                  variant="contained"
                  style={{ width: 120 }}
                >
                  Create PO
                </Button>
              </Grid>
            </TableCell>
          )}
          <TableCell>
            <Grid style={{ marginRight: 10 }}>
              <Button
                onClick={addOrder}
                variant="contained"
                style={{ width: 120 }}
              >
                Add Order
              </Button>
            </Grid>
            <Grid style={{ marginTop: 10 }}>
              <Button
                onClick={orderCreateApi}
                variant="contained"
                style={{ width: 120 }}
                disabled={buttonDis || isDisable(orignalProducts, numOrder)}
              >
                order Create
              </Button>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse
              in={openTableIndex.isOpen && index == openTableIndex.index}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ margin: 1 }}>
                <Table
                  size="small"
                  aria-label="purchases"
                  style={{ overflowX: "scroll" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Total Quantity</TableCell>
                      {numOrder.map((item, index) => (
                        <TableCell style={{ width: 200 }}>
                          <div style={{ position: "relative" }}>
                            Order {item}
                            {numOrder.length - 1 == index && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  right: 10,
                                  cursor: "pointer",
                                  zIndex: 200,
                                }}
                                onClick={() => removeOrder(item)}
                              >
                                <h3>X</h3>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orignalProducts.map((pro) => (
                      <TableRow>
                        <TableCell>{pro.name}</TableCell>
                        <TableCell>{pro.quantity}</TableCell>
                        {numOrder.map((item) => (
                          <TableCell style={{ width: 200 }}>
                            {/* {pro[`quantity${item}`]} */}
                            <TextField
                              type="number"
                              value={pro[`quantity${item}`]}
                              onChange={(t) =>
                                quantityChangeOfProduct(
                                  pro._id,
                                  `quantity${item}`,
                                  t.target.value
                                )
                              }
                              style={{ width: 150 }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={1}>Total Payable</TableCell>
                      <TableCell>{order?.totalPayableAmount}</TableCell>
                      {numOrder.map((item) => (
                        <TableCell>
                          {/* <Button
                            variant="contained"
                            disabled={isDisable(
                              orignalProducts,
                              numOrder
                            )}
                          >
                            Create
                          </Button> */}
                          <p>
                            {getTotalPaybel(orignalProducts, numOrder, item)}
                          </p>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  function CollapesRowForChildProduct({ order, index }) {
    const [open, setOpen] = React.useState(false);
    const [selectOrganizations,setSelectOrganization] = useState(order?.organization||1)


    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpenTable({
                  index: index,
                  isOpen: !openTableIndex.isOpen,
                });
              }}
            >
              {openTableIndex.index == index && openTableIndex.isOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
          <TableCell>{order.numericOrderId}</TableCell>
          <TableCell>{order?.name}</TableCell>
          <TableCell>{order?.phoneNumber}</TableCell>
          <TableCell>
            {moment(order.createdAt).format("HH:mm A - DD/MM/YYYY")}
          </TableCell>

          <TableCell>{order?.seller?.gstNumber || "not found"}</TableCell>
          <TableCell>{order?.seller?.businessName || "not found"}</TableCell>
          <TableCell>{order?.totalPayableAmount}</TableCell>
          <TableCell> <Select
            onChange={(v)=>setSelectOrganization(v.target.value)}
            value={selectOrganizations}
            label='Select Organization'
            disabled={order?.organization}
          >
            <MenuItem value={1}>Emergent</MenuItem>
            <MenuItem value={2}>Flordia Beauty</MenuItem>
            </Select> </TableCell>
          <TableCell>
            {
              <>
                <>
                  {order?.ucSalesOrderCreatedAt &&
                  moment(order?.ucSalesOrderCreatedAt).add(6, "minutes") <=
                    moment(new Date()) ? (
                    <TableCell>
                      <Button
                        size="small"
                        disabled={disableBtn || order?.awbNumber}
                        variant="outlined"
                        onClick={(e) => {
                          setShipmentPopup({
                            open: true,
                            orderId: order?._id,
                            orderName: "shiprocket",
                            type: "unicommerce",
                            organizations:selectOrganizations
                            
                          });
                          setBulkOrderFromRocket(false);
                        }}
                      >
                        {order?.awbNumber ? "Already shiped" : "Ship"}
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Button
                        size="small"
                        disabled={
                          disableBtn &&
                          order?.ucSalesOrderCreatedAt &&
                          moment(new Date()) <
                            moment(order?.ucSalesOrderCreatedAt).add(
                              6,
                              "minutes"
                            )
                        }
                        variant="outlined"
                        onClick={(e) => createOrder(order?._id, 1,selectOrganizations)}
                      >
                        {order?.ucSalesOrderCreatedAt &&
                        moment(new Date()) <
                          moment(order?.ucSalesOrderCreatedAt).add(6, "minutes")
                          ? `Wait ${Math.ceil(
                              moment
                                .duration(
                                  moment(order?.ucSalesOrderCreatedAt)
                                    .add(6, "minutes")
                                    .diff(moment(new Date()))
                                )
                                .asMinutes()
                            )} min`
                          : "Create Order"}
                      </Button>
                    </TableCell>
                  )}
                </>
              </>
            }
          </TableCell>
        
          <TableCell>
                      {order.awbNumber && order.orderInventoryUpdatedAtZoho==false && (
            <TableCell>
              <Grid style={{ marginTop: 10 }}>
                <Button
                  onClick={() => createPoOnZoho(order._id)}
                  variant="contained"
                  style={{ width: 120 }}
                >
                  Create PO
                </Button>
              </Grid>
            </TableCell>
          )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse
              in={openTableIndex.isOpen && index == openTableIndex.index}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Id</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Total Payable</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>CN Discount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.childOrders.map((childOrder, idx) => (
                      <TableRow key={childOrder.createdAt}>
                        <TableCell component="th" scope="row">
                          {childOrder.numericOrderId}
                        </TableCell>
                        <TableCell>
                          {childOrder?.discountBreakupInfo1.reduce(
                            (acc, dis) => {
                              const value = Object.values(dis)[0]; // Extracting the value from each object
                              return acc + Math.round(value);
                            },
                            0
                          )}
                        </TableCell>
                        <TableCell>{childOrder.totalPayableAmount}</TableCell>
                        <TableCell>
                          <Link
                            to={`/bulkorderdetails/${childOrder._id}`}
                            style={{
                              textDecoration: "none",
                              color:
                                childOrder.paymentMethod == 1
                                  ? "#000"
                                  : "green",
                            }}
                            target="_blank"
                          >
                            Details
                          </Link>
                        </TableCell>

                        {childOrder?.status == 1 &&
                        order.awbNumber &&
                        !childOrder?.awbNumber ? (
                          <TableCell>
                            <Button
                              size="small"
                              disabled={disableBtn}
                              variant="outlined"
                              onClick={(e) => {
                                handelShippingMode(childOrder?._id);
                                // setShipmentPopup({
                                //   open: true,
                                //   orderId: childOrder?._id,
                                //   orderName: "rocketBox",
                                //   type: "unicommerce",
                                // });
                                // setBulkOrderFromRocket(true);
                              }}
                            >
                              Ship
                            </Button>
                            {childOrder?.totalPayableAmount >= 50000 &&
                              childOrder?.ewayBillNumber == undefined && (
                                <Button
                                  size="small"
                                  disabled={disableBtn}
                                  style={{ marginLeft: 10 }}
                                  variant="outlined"
                                  onClick={(e) => {
                                    setEwayBillModal({
                                      orderId: childOrder._id,
                                      modal: true,
                                    });
                                  }}
                                >
                                  Update Eway Bill
                                </Button>
                              )}
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell>
                          {/* {!childOrder?.awbNumber && (
                            <Grid>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setCnDiscountModal({
                                    modal: true,
                                    orderId: childOrder?._id,
                                  });
                                  setCnDiscount(
                                    childOrder?.cnDiscountPercentage || 10
                                  );
                                }}
                              >
                                Update CN Discount
                              </Button>
                            </Grid>
                          )} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <>
      <MainCard content={false} title="Bulk Orders">
      <Grid container spacing={2} alignItems={'center'}>
        <Grid xs={3} item>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
          />

        </Grid>
        <Grid item xs={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            variant="contained"
            style={{
              width: "100%",
            }}
            loadingIndicator="loading..."
            onClick={() => dataLoad()}
            loading={loading}
          >Submit
          </LoadingButton>
        </Grid>
        </Grid>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid row container alignItems="center">
              <Grid item xs={12} lg={4} ml={2}>
                <Button
                  onClick={() => dataLoad(page, rowsPerPage)}
                  variant="contained"
                  style={{ marginLeft: 10 }}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>

        <TableContainer style={{ overflowX: "auto" }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Order Id </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>PhoneNumber</TableCell>
                <TableCell>Order Date </TableCell>
                <TableCell>GST Number</TableCell>
                <TableCell>Business Name</TableCell>
                <TableCell>Total Payabel</TableCell>
                <TableCell>Select Organizations </TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderdata &&
                orderdata.length > 0 &&
                orderdata.map((order, index) =>
                  order?.childOrders.length > 0 ? (
                    <CollapesRowForChildProduct order={order} index={index} />
                  ) : (
                    <CollapesRow order={order} index={index} />
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* table pagination */}
        {/* <TablePagination
          rowsPerPageOptions={[25, 200]}
          component="div"
          count={totalpage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </MainCard>
      <Ordershippopup
        openShipmentPopup={openShipmentPopup}
        setShipmentPopup={setShipmentPopup}
        bulkOrder={true}
        bulkOrderFromRocket={bulkOrderFromRocket}
        isrocketOrPorter={openShipmentPopup.isrocketOrPorter}
      />
      <AlertModal
        visible={shippingModeModal}
        orderId={orderIdClick}
        onClose={() => {
          setShippingModeModal(false);
        }}
        okPress={() => {
          setTimeout(() => {
            setShipmentPopup({
              open: true,
              orderId: orderIdClick,
              orderName: "rocketBox",
              type: "unicommerce",
              isrocketOrPorter: true,
            });
            setBulkOrderFromRocket(true);
          }, 200);
          setShippingModeModal(false);
        }}
      />
      <Dialog
        open={ewayBillModal.modal}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            <Grid container>
              <Grid item lg={6}>
                <Typography variant="h3">Update Eway Bill Number</Typography>
              </Grid>
              <Grid item lg={6} justifyContent={"flex-end"} display={"flex"}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setEwayBillModal({
                      modal: false,
                      orderId: "",
                    })
                  }
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid container>
            <Grid item lg={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={8} md={8} sm={12}>
              <DialogContentText id="alert-dialog-description">
                <TextField
                  type="text"
                  name="numofbox"
                  value={ewayBillNumber}
                  onChange={(e) => setEwayBillNumber(e.target.value)}
                  placeholder="Enter Eway Bill Number"
                  fullWidth
                  required
                />
              </DialogContentText>
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => updateEwayBillNumber(ewayBillNumber)}
                >
                  Update
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={cnDiscountModal.modal}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            <Grid container>
              <Grid item lg={6}>
                <Typography variant="h3">Update CN Discount</Typography>
              </Grid>
              <Grid item lg={6} justifyContent={"flex-end"} display={"flex"}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setCnDiscountModal({
                      modal: false,
                      orderId: "",
                    })
                  }
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid container>
            <Grid item lg={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={8} md={8} sm={12}>
              <DialogContentText id="alert-dialog-description">
                <TextField
                  type="text"
                  name="numofbox"
                  value={cnDiscount}
                  onChange={(e) => setCnDiscount(e.target.value)}
                  placeholder="Enter Cn Discount"
                  fullWidth
                  required
                />
              </DialogContentText>
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => updateCnDiscount(ewayBillNumber)}
                >
                  Update
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
