export const initialState = {
    products: []
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const vendorCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_WHOLE_CART':
            let products = action.payload.filter((item) => item?.quantity)
            return {
                ...state,
                products: products
            };
        case 'ADD_PRODUCT_TO_CART':
            return {
                ...state,
                products: [...state.products, action.payload]
            };
        case 'REMOVE_PRODUCT_FROM_CART':
            let prd = state?.products?.filter((item) => item._id !== action.payload._id)
            return {
                ...state,
                products: prd
            };
        case 'MAKE_CART_EMPTY':
            return {
                products: [],
            };
        case 'INCREASE_PRODUCT_QUANTITY':
            let updatesAfterInc = state?.products?.map((item) => (item._id === action.payload._id) ? { ...item, quantity: item.quantity + 1 } : item)
            return {
                ...state,
                products: updatesAfterInc
            };
        case 'DESCREASE_PRODUCT_QUANTITY':
            let updatesAfterDes = state?.products?.map((item) => (item._id === action.payload._id) ? { ...item, quantity: item.quantity - 1 } : item)
            return {
                ...state,
                products: updatesAfterDes
            };

        default:
            return state;
    }
};

export default vendorCartReducer;
