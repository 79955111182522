import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingStore,
} from "@tabler/icons";

// constant
const icons = {
    IconUserCheck: IconUserCheck,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconBuildingStore: IconBuildingStore,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//

export const MainDashboard = {
    id: "main",
    title: <FormattedMessage id="Dashboard" />,
    type: "group",
    children: [
        {
            id: "Default",
            title: <FormattedMessage id="Dashboard" />,
            type: "item",
            url: "/dashboard/default",
            breadcrumbs: false,

        },
    ],
};
