import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import snackbarReducer from "./snackbarReducer";
import sellerReducers from "./sellerReducers";
import accountReducer from "./accountReducer";
import salesleadsReducers from "./salesleadsReducers";
import dialerReducer from "./dialerReducer";
import { persistStore, persistReducer } from "redux-persist";
import dealReducer from './dealReducer';
import storage from 'redux-persist/lib/storage';
import vendorCartReducer from "./vendorCartReducer";
//-----------------------|| COMBINE REDUCER ||-----------------------//

const persistConfig = {
  key: 'main-root',
  storage
}


const reducer = combineReducers({
  customization: customizationReducer,
  snackbar: snackbarReducer,
  sellerStore: sellerReducers,
  account: accountReducer,
  salesleadstore: salesleadsReducers,
  dialer: dialerReducer,
  deals: dealReducer,
  vendorCart: vendorCartReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export { persistedReducer }
export default reducer;
