import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { breakState, dialerState } from '../../../action/dialerAction';

const useStyles = makeStyles((theme) => ({
    circle: {
        display: "flex",
        flexWrap: "wrap",
        height: " 20px",
        width: "20px",

        borderRadius: "50%",
        position: "relative",
        top: "1.6rem",
        left: "6rem",
    },

}));

export default function DialerBreakState() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [initalState, setInitialState] = React.useState('morning break')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectReadyState = (value) => {
        setInitialState(value);
        dispatch(breakState(value))
        setAnchorEl(null);

    }


    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant='outlined'
                color='warning'
            >
                BREAK
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => selectReadyState('MORNING_BREAK')}>Morning Break</MenuItem>
                <MenuItem onClick={() => selectReadyState('LUNCH')}>Lunch</MenuItem>
                <MenuItem onClick={() => selectReadyState('TRAINING')}>Traning</MenuItem>
                <MenuItem onClick={() => selectReadyState('EVENING_BREAK')}>Eve Break</MenuItem>
                <MenuItem onClick={() => selectReadyState('QUICK_BREAK')}>QUICK BREAK</MenuItem>
            </Menu>
        </div>
    );
}
