import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
} from "@tabler/icons";

// constant
const icons = {
    IconUserCheck: IconUserCheck,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconClipboardList: IconClipboardList,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//

export const Role12Reports = {
    id: "AllReports",
    title: <FormattedMessage id="All Reports" />,
    type: "group",
    children: [
        {
            id: "Reports",
            title: <FormattedMessage id="Reports" />,
            type: "collapse",
            icon: icons["IconClipboardList"],
            children: [
                {
                    id: "buyumedetails",
                    title: <FormattedMessage id="BuyuMe Details" />,
                    type: "item",
                    url: "/admin/reports/buyumedetails2",
                },
                {
                    id: "Monthly Summary",
                    title: <FormattedMessage id="Monthly Summary" />,
                    type: "item",
                    url: "/admin/reports/buyumedetails",
                },
                {
                    id: "mostsellingproduct",
                    title: <FormattedMessage id="Most Selling pending products" />,
                    type: "item",
                    url: "/reports/mostsellingproduct",
                },
                {
                    id: "mostsellingbrands",
                    title: <FormattedMessage id="Most Selling Brands" />,
                    type: "item",
                    url: "/admin/reports/mostsellingbrands",
                },
                {
                    id: "productorderdetails",
                    title: <FormattedMessage id="Product Orders Details" />,
                    type: "item",
                    url: "/reports/productorderdetails",
                },
                {
                    id: "referreport",
                    title: <FormattedMessage id="Refer Report" />,
                    type: "item",
                    url: "/admin/reports/referreport",
                },
                {
                    id: "downloadreports",
                    title: <FormattedMessage id="Download Report" />,
                    type: "item",
                    url: "/admin/reports/downloadreports",
                },
                {
                    id: "DownloadcohortReport",
                    title: <FormattedMessage id="Order Date Cohort" />,
                    type: "item",
                    url: "/admin/reports/OrderDateCohort",
                },
                {
                    id: "BrandWiseRetailReport",
                    title: <FormattedMessage id="Brand Wise Retail Report" />,
                    type: "item",
                    url: "/reports/BrandWiseRetailReport",
                },
            ],
        },
    ],
};
