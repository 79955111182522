// import { SALES_LEADS_ADD, TOGGLE_SHOW_LEAD } from "./actions";
export const initialState = {
    leads: []
}

const salesleadsReducers = (state = initialState, action) => {
    switch (action.type) {
        case 'SALES_LEADS_ADD':
            let lead = [...state.leads]
            console.log("action", action)
            lead = action.payload
            return {
                ...state,
                leads: lead
            }

        case 'TOGGLE_SHOW_LEAD':
            let allLeads = [...state.leads]
            allLeads[action.payload.index].show = action.payload.show
            return {
                ...state,
                leads: allLeads
            }
        default:
            return state
    }
}
export default salesleadsReducers