import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingStore,
} from "@tabler/icons";

// constant
const icons = {
    IconUserCheck: IconUserCheck,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconBuildingStore: IconBuildingStore,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
let item = []
let role = localStorage.getItem('setRole')
let phoneNumber = localStorage.getItem('phoneNumber')
if (Number(role) === 2 || Number(role) === 17 || phoneNumber == '8076096127') {
    item = [
        {
            id: "Product Wise Reports",
            title: <FormattedMessage id="Product Wise Report" />,
            type: "item",
            url: "/financialreport/productWiseReport",
        },
        {
            id: "New Product Wise Reports",
            title: <FormattedMessage id="New Product Wise Report" />,
            type: "item",
            url: "/financialreport/newproductWiseReport",
        },
    ]
} 
else if(phoneNumber=='9899911403'||phoneNumber=='8384080103'){
    item = [
        {
            id: "Product Wise Reports",
            title: <FormattedMessage id="Product Wise Report" />,
            type: "item",
            url: "/financialreport/productWiseReport",
        }
    ]
}
else if (phoneNumber == "8178277221") {
    item = [
        {
            id: "Product Wise Reports",
            title: <FormattedMessage id="Product Wise Report" />,
            type: "item",
            url: "/financialreport/productWiseReport",
        },
        {
            id: "Brand Wise Report",
            title: <FormattedMessage id="Brand Wise Report" />,
            type: "item",
            url: "/financialreport/brandWiseReport",
        },
    ]
}

else {
    item = [
        {
            id: "Orders Reports",
            title: <FormattedMessage id="Orders Reports" />,
            type: "item",
            url: "/financialreport/downloadorders",
        },
        {
            id: "Product Wise Reports",
            title: <FormattedMessage id="Product Wise Report" />,
            type: "item",
            url: "/financialreport/productWiseReport",
        },
        {
            id: "New Product Wise Reports",
            title: <FormattedMessage id="New Product Wise Report" />,
            type: "item",
            url: "/financialreport/newproductWiseReport",
        },
        {
            id: "Product Wise Reports V2",
            title: <FormattedMessage id="Product Wise Report V2" />,
            type: "item",
            url: "/financialreport/productWiseReportV2",
        },
        {
            id: "Brand Wise Report",
            title: <FormattedMessage id="Brand Wise Report" />,
            type: "item",
            url: "/financialreport/brandWiseReport",
        },
        {
            id: "ReconcileReport",
            title: <FormattedMessage id="Cash Reconcile Report" />,
            type: "item",
            url: "/financialreport/ReconcileReport",
        },
        {
            id: "cash received",
            title: <FormattedMessage id="Cash Received: InTransit" />,
            type: "item",
            url: "/financialreport/cash-intransit",
        },
        {
            id: "Order Details By Cashback Created",
            title: <FormattedMessage id="Order Details: By Cashback Created" />,
            type: "item",
            url: "/financialreport/order-by-cashback-created",
        },
        {
            id: "Return Pickup Report",
            title: <FormattedMessage id="Return Pickup Report" />,
            type: "item",
            url: "/financialreport/return-pickup-report",
        }
    ]
}


export const FinancialReports = {
    id: "Financial",
    title: <FormattedMessage id="Financial Report" />,
    type: "group",
    children: [
        {
            id: "Financial Report",
            title: <FormattedMessage id="Financial Report" />,
            type: "collapse",
            icon: icons["IconBuildingStore"],
            children: item
        },
    ],
};
