import { SELLER_ADD } from "./actions";
export const initialState = {};
const sellerReducers = (state = initialState, action) => {
  switch (action.type) {
    case SELLER_ADD: {
      // console.log("type", action.payload);
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
export default sellerReducers;
