const initialValue = {
    startDate: "",
    endDate: "",
    active: null,
    name: "",
    dealId: "",
    discountType: null,
    dealType: null,
    _id: ""

}

const dealReducer = (state = initialValue, action) => {
    switch (action.type) {
        case 'INITIAL_DEAL':
            console.log("action, state", action, state);
            return {
                ...state,
                startDate: "",
                endDate: "",
                active: null,
                name: "",
                dealId: "",
                discountType: null,
                dealType: null,
                _id: ""
            }
        case 'ADD_DEAL':
            console.log("action on dsfskjdfbkfklsf", action.payload)
            return {
                ...state,
                startDate: action.payload,
                endDate: action.payload,
                active: action.payload,
                name: action.name,
                dealId: action.dealId,
                discountType: action.discountType,
                dealType: action.dealType,
                _id: action._id
            }

        default:
            return state;
    }
}

export default dealReducer;