import axios from 'axios'
import { useSelector } from 'react-redux';

const BASE_URL = process.env.REACT_APP_PATH
let token = localStorage.getItem('serviceToken')
const ApiInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        "Sec-Fetch-Site": "none",
        'Authorization': 'Bearer '+token
    }
});

export default ApiInstance;