import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconList,
  IconBuildingStore,
  IconClipboardList,
  IconDialpad

} from "@tabler/icons";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
  IconClipboardList: IconClipboardList,
  IconList: IconList,
  IconDialpad: IconDialpad

};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
let role = localStorage.getItem('setRole')
const accessNumbers = ["9773831187", "8826956978", "9911034338", "9953304664", "9719836187", "9177139113", '9873380793', "8076833686"]
let item2 = [
  {
    id: "Orders with concern",
    title: <FormattedMessage id="Orders with concern" />,
    type: "item",
    url: "/inventory/FreeTrialOrders",
    // breadcrumbs: false
  },
  {
    id: "All Orders with concern",
    title: <FormattedMessage id="All Orders with concern" />,
    type: "item",
    url: "/inventory/all-orders-with-concern",
    // breadcrumbs: false
  },
  {
    id: "Auto Cancelled Orders",
    title: <FormattedMessage id="Auto Cancelled Orders" />,
    type: "item",
    url: "/inventory/auto-cancelled-orders",
    // breadcrumbs: false
  },
  {
    id: "Orders Without Address",
    title: <FormattedMessage id="Orders Without Address" />,
    type: "item",
    url: "/inventory/orders-without-address",
  },
  {
    id: "Blocked Agents",
    title: <FormattedMessage id="Blocked Agents" />,
    type: "item",
    url: "/inventory/blocked-agents",
  },

]


let phoneNumber = localStorage.getItem('phoneNumber')

if (Number(phoneNumber) === 8448300516 || Number(phoneNumber) === 9719836187) {
  item2.push({
    id: "Brands Limit And Discount",
    title: <FormattedMessage id="Brands Limit And Discount" />,
    type: "item",
    url: "/inventory/brands-limit-and-discount",
  })
}
let item = []

if (role == "14") {
  item = [
    {
      id: "Tickets",
      title: <FormattedMessage id="Tickets" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "Cancelled Order Tickets",
          title: <FormattedMessage id="Cancelled Order Tickets" />,
          type: "item",
          url: "/inventory/cancelledOrderTickets",
        }
      ]
    },
    {
      id: "Audit Orders",
      title: <FormattedMessage id="Audit Orders" />,
      type: "collapse",
      icon: icons["IconList"],
      children: [
        {
          id: "Orders with concern",
          title: <FormattedMessage id="Orders with concern" />,
          type: "item",
          url: "/inventory/FreeTrialOrders",
        },
      ]
    },
  ]
} else {
  if (role == "16" || role == "18") {
    item = []
  } else if (role == "17") {
    if (phoneNumber == "9999957559" || phoneNumber == "8076833686") {
      item = [
        {
          id: "Audit Orders",
          title: <FormattedMessage id="Audit Orders" />,
          type: "collapse",
          icon: icons["IconList"],
          children: [
            {
              id: "Orders with concern",
              title: <FormattedMessage id="Orders with concern" />,
              type: "item",
              url: "/inventory/FreeTrialOrders",
            }
          ]
        }
      ]
    }
  } else if (role == "5") {
    item = []
    if (Number(phoneNumber) === 8368765621) {
      item.push(
        {
          id: "DeliveryCall",
          title: <FormattedMessage id="Delievery Call" />,
          type: "collapse",
          icon: icons["IconDialpad"],
          children: [
            {
              id: "Dialer",
              title: <FormattedMessage id="Dialer" />,
              type: "item",
              url: "/inventory/dailer",
            },
          ],
        }
      )
    }
  } else {
    item = [
      {
        id: "DeliveryCall",
        title: <FormattedMessage id="Delievery Call" />,
        type: "collapse",
        icon: icons["IconDialpad"],
        children: [
          {
            id: "Dialer",
            title: <FormattedMessage id="Dialer" />,
            type: "item",
            url: "/inventory/dailer",
          },
        ],
      },

      {
        id: "Reports",
        title: <FormattedMessage id="Reports" />,
        type: "collapse",
        icon: icons["IconClipboardList"],
        children: [
          {
            id: "AgentCall Report",
            title: <FormattedMessage id="Agent Call Report" />,
            type: "item",
            url: "/inventory/agentWiseCallReport",
          },
          {
            id: "AgentWise Order Report",
            title: <FormattedMessage id="AgentWise Order Report" />,
            type: "item",
            url: "/inventory/agentWiseOrderReport",
          },
        ],
      },


      {
        id: "Tickets",
        title: <FormattedMessage id="Tickets" />,
        type: "collapse",
        icon: icons["IconClipboardList"],
        children: [
          {
            id: "AllTickets",
            title: <FormattedMessage id="All Tickets" />,
            type: "item",
            url: "/inventory/allTickets",
          },
          {
            id: "MyTickets",
            title: <FormattedMessage id="My Tickets" />,
            type: "item",
            url: "/inventory/myTickets",
          },
          {
            id: "Audit Ticket Cleared By Agents",
            title: <FormattedMessage id="Audit Ticket Cleared By Agents" />,
            type: "item",
            url: "/inventory/audit-ticket-cleared-by-agents",
          },
          {
            id: "Upload NDR",
            title: <FormattedMessage id="Upload NDR Sheet" />,
            type: "item",
            url: "/inventory/uploadNDR",
          },
          {
            id: "Cancelled Order Tickets",
            title: <FormattedMessage id="Cancelled Order Tickets" />,
            type: "item",
            url: "/inventory/cancelledOrderTickets",
          },
          {
            id: "Pre Audit Ticket Report",
            title: <FormattedMessage id="Pre Audit Ticket Report" />,
            type: "item",
            url: "/inventory/preAuditTickets",
          },
          {
            id: "Ticket Detailed Report",
            title: <FormattedMessage id="Ticket Detailed Report" />,
            type: "item",
            url: "/inventory/TicketDetailedReport",
          },
          {
            id: "Audit Agent Report",
            title: <FormattedMessage id="Audit Agent Report" />,
            type: "item",
            url: "/inventory/audit-agent-report",
          },
        ],
      },
      {
        id: "Audit Orders",
        title: <FormattedMessage id="Audit Orders" />,
        type: "collapse",
        icon: icons["IconList"],
        children: item2
      },

    ]
  }
  if (role == "1" || role == "2") {
    item.push({
      id: "Mela Section",
      title: <FormattedMessage id="Mela Section" />,
      type: "collapse",
      icon: icons["IconClipboardList"],
      children: [
        {
          id: "Mela Orders",
          title: <FormattedMessage id="Mela Orders" />,
          type: "item",
          url: "/inventory/mela-orders",
        },
        {
          id: "Create Mela",
          title: <FormattedMessage id="Create Mela" />,
          type: "item",
          url: "/inventory/create-mela/",
        },
        {
          id: "Mela Entry Exit",
          title: <FormattedMessage id="Mela Entry Exit" />,
          type: "item",
          url: "/inventory/mela-entry-exit",
        },
        {
          id: "All Mela",
          title: <FormattedMessage id="All Mela" />,
          type: "item",
          url: "/inventory/all-mela",
        },
        {
          id: "Mela Banners",
          title: <FormattedMessage id="Mela Banners" />,
          type: "item",
          url: "/inventory/mela-banners",
        },
        {
          id: "Mela Scans Report",
          title: <FormattedMessage id="Mela Scans Report" />,
          type: "item",
          url: "/inventory/mela-scans-report",
        },
        {
          id: "Mela Report",
          title: <FormattedMessage id="Mela Report" />,
          type: "item",
          url: "/inventory/mela-report",
        }
      ]
    })
  }
}

if (['9359536247', '9119008412', '9027110892'].includes(phoneNumber)) {
  item.push({
    id: "Mela Section",
    title: <FormattedMessage id="Mela Section" />,
    type: "collapse",
    icon: icons["IconClipboardList"],
    children: [
      {
        id: "Mela Orders",
        title: <FormattedMessage id="Mela Orders" />,
        type: "item",
        url: "/inventory/mela-orders",
      },
      {
        id: "Create Mela",
        title: <FormattedMessage id="Create Mela" />,
        type: "item",
        url: "/inventory/create-mela/",
      },
      {
        id: "Mela Entry Exit",
        title: <FormattedMessage id="Mela Entry Exit" />,
        type: "item",
        url: "/inventory/mela-entry-exit",
      },
      {
        id: "All Mela",
        title: <FormattedMessage id="All Mela" />,
        type: "item",
        url: "/inventory/all-mela",
      }
    ]
  })
}
if (['7022888630', '9315967848', '9315116177'].includes(phoneNumber)) {
  item.push({
    id: "Mela Section",
    title: <FormattedMessage id="Mela Section" />,
    type: "collapse",
    icon: icons["IconClipboardList"],
    children: [
      {
        id: "Mela Orders",
        title: <FormattedMessage id="Mela Orders" />,
        type: "item",
        url: "/inventory/mela-orders",
      },
      {
        id: "Create Mela",
        title: <FormattedMessage id="Create Mela" />,
        type: "item",
        url: "/inventory/create-mela/",
      },
      {
        id: "Mela Entry Exit",
        title: <FormattedMessage id="Mela Entry Exit" />,
        type: "item",
        url: "/inventory/mela-entry-exit",
      },
      {
        id: "All Mela",
        title: <FormattedMessage id="All Mela" />,
        type: "item",
        url: "/inventory/all-mela",
      },
      {
        id: "Mela Banners",
        title: <FormattedMessage id="Mela Banners" />,
        type: "item",
        url: "/inventory/mela-banners",
      }
    ]
  })
}

item.push({
  id: "Return Invoice",
  title: <FormattedMessage id="Return Invoice" />,
  type: "item",
  url: "/ops/return-invoice",
})



export const Inventory = {
  id: "Inventory",
  title: <FormattedMessage id="Inventory" />,
  type: "group",
  children: item
};
