import React from 'react'
import { FormattedMessage } from "react-intl";
import {
    IconTrendingUp,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingStore,
} from "@tabler/icons";
const icons = {
    IconTrendingUp: IconTrendingUp,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconBuildingStore: IconBuildingStore,
};
let item = []
let phoneNumber = localStorage.getItem('phoneNumber')
const accessNumbers = ["9911034338", "9177139113", "9719836187", "9625342361", "9907125180", "9315304707", "9643011118", "9318434445", "7048907291", "9354815727", "9354264137", "7217854907", "9354275752", "9315307958"]
const role = localStorage.getItem('setRole')

if ((role == "4") && accessNumbers.includes(phoneNumber)) {
    item = [
        {
            id: "Free Product Pending Approval",
            title: <FormattedMessage id="Free Product Pending Approval" />,
            type: "item",
            url: "/coupon/free-product-pending-approval",
            breadcrumbs: true,
        },
    ]
} else if (role == "1" || role == "2") {
    item = [
        // {
        //     id: "Pending Order",
        //     title: <FormattedMessage id="Coupon Report" />,
        //     type: "item",
        //     url: "/coupon/coupon-report",
        //     breadcrumbs: true,
        // },
        {
            id: "Free Product Pending Approval",
            title: <FormattedMessage id="Free Product Pending Approval" />,
            type: "item",
            url: "/coupon/free-product-pending-approval",
            breadcrumbs: true,
        }
    ]
} else {
    item = []
}


export const CouponRelated = {
    id: "Growth",
    title: <FormattedMessage id="Growth" />,
    type: "group",
    children: [
        {
            id: "Coupon Related ",
            title: <FormattedMessage id="Growth Related" />,
            type: "collapse",
            icon: icons["IconTrendingUp"],
            children: item
        },
    ],
};
