import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
} from "@tabler/icons";

// constant
const icons = {
    IconUserCheck: IconUserCheck,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconClipboardList: IconClipboardList,
};



//-----------------------|| Customer MENU ITEMS ||-----------------------//

const phoneNumber = localStorage.getItem('phoneNumber');
let accessList = [
    {
    id: "Packing Order",
    title: <FormattedMessage id="Packing Order" />,
    type: "item",
    url: "/packing/order",
},
    {
    id: "Non Crm Packing Order",
    title: <FormattedMessage id="Non Crm Packing Order" />,
    type: "item",
    url: "/packing/nonCrmorder",
},
];



export const Packing = {
    id: "Packing Order",
    title: <FormattedMessage id="Packing Order" />,
    type: "group",
    children: accessList,
};
