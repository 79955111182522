import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";

import MainCard from "../../ui-component/cards/MainCard";
import SubCard from "../../ui-component/cards/SubCard";
import { gridSpacing } from "../../store/constant";
import CalendarTodayTwoToneIcon from "@material-ui/icons/CalendarTodayTwoTone";
import DescriptionTwoToneIcon from "@material-ui/icons/DescriptionTwoTone";
import PhoneAndroidTwoToneIcon from "@material-ui/icons/PhoneAndroidTwoTone";


const useStyles = makeStyles((theme) => ({
  accountTab: {
    "& a": {
      minHeight: "auto",
      minWidth: "10px",
      padding: "12px 8px",
      marginRight: "18px",
      color: theme.palette.grey[600],
    },
    "& a.Mui-selected": {
      color: theme.palette.primary.main,
    },
    "& a > span": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    "& a > span > svg": {
      marginBottom: "0px !important",
      marginRight: "10px",
    },
    "& a > span > span + svg": {
      margin: "0px 0px 0px auto !important",
      width: "14px",
      height: "14px",
    },
  },

  customerHeadDetails: {
    "& > svg": {
      width: "15px",
      height: "15px",
      verticalAlign: "text-top",
      marginRight: "5px",
      marginTop: "2px",
    },
  },

  tableResponsive: {
    overflowX: "auto",
  },
  totalBillAmount: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const BulkOrderDetails = () => {
  const classes = useStyles();
  const { OrderId } = useParams();
  const [singleOrder, setSingleOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const role = localStorage.getItem("setRole");



  const dataLoad = () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_PATH}/role1/allCapturedOrders/${OrderId}`)
      .then(res => {
        if (res?.data?.success) {
          setSingleOrder(res?.data?.data[0] || []);
          setLoading(false)
        }
       
      }).catch(err => {
        setLoading(false)
      })
  };





  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    dataLoad();
  }, []);


  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <MainCard>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        className={classes.accountTab}
        aria-label="simple tabs example"
        sx={{ mb: 3 }}
      >
        <Tab
          icon={<DescriptionTwoToneIcon />}
          component={Link}
          to="#"
          label="Details"
          {...a11yProps(0)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <SubCard
              title='Order Details'
              secondary={
                <Typography variant="subtitle1">
                  Place On : {new Date(singleOrder?.createdAt).toDateString()}{" "}
                  {new Date(singleOrder?.createdAt).toLocaleTimeString()}
                </Typography>
              }
            >
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.customerHeadDetails}
                      >
                        <CalendarTodayTwoToneIcon /> {singleOrder?.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={classes.customerHeadDetails}
                      >
                        <PhoneAndroidTwoToneIcon /> {singleOrder?.phoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <Grid container spacing={gridSpacing}>
                      {singleOrder?.address ? (
                        <Grid item sm={10}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4">
                                Shipping address
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={0}>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    First name : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.name}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Phone : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.phoneNumber}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={0}>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Address Line 1: &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.address1}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Address Line 2 : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.address2}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Landmark : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.landmark}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Postal code : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.postalCode}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    City : &nbsp;
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {singleOrder?.address?.city}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item sm={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4">
                                Address Not Found
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h4">Payment method</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  {singleOrder?.paymentMethod === 1
                                    ? " Cash Payment"
                                    : " Online Payment"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                  Transaction ID : &nbsp;
                                  <Typography component="span" variant="body2">
                                    {singleOrder?.numericOrderId}
                                  </Typography>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
          {(singleOrder?.rewardProducts?.length > 0) && <Grid item xs={12}>
            <SubCard title="Reward Products" content={false}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.tableResponsive}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell >Product Name</TableCell>
                        <TableCell >Quantity</TableCell>
                        <TableCell >Amount</TableCell>
                        <TableCell >Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {singleOrder?.rewardProducts?.map((row, index) => {
                        return (
                          <TableRow key={row?.rewardProductId}>
                            <TableCell> <Typography variant="subtitle1">{row?.rewardProductName} </Typography></TableCell>
                            <TableCell > {row?.quantity} </TableCell>
                            <TableCell>{Math.round(row?.mrp)}</TableCell>
                            <TableCell> {Math.round((row?.mrp * row?.quantity))}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>}
          <Grid item xs={12}>
            <SubCard title="All Products" content={false}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.tableResponsive}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell >Product Name</TableCell>
                        <TableCell >Quantity</TableCell>
                        <TableCell >Amount</TableCell>
                        <TableCell >Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {singleOrder?.products?.map((row, index) => {
                        return (
                          <TableRow key={row?._id}>
                            <TableCell> <Typography variant="subtitle1">{row?.name} </Typography></TableCell>
                            <TableCell > {row?.quantity} </TableCell>
                            <TableCell>{Math.round(row?.mrp)}</TableCell>
                            <TableCell> {Math.round((row?.mrp * row?.quantity))}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <SubCard sx={{ mx: 3, mb: 3 }} contentClass={classes.totalBillAmount} >
                    <Grid
                      container
                      justifyContent='space-between'
                      spacing={gridSpacing}
                    >
                      <Grid item sm={6} md={6}>
                        <Grid container item >

                          {
                            role == 1 || role == 4 ?
                              singleOrder?.couponCode == null ?null : <Typography variant="h2">Coupon already applied</Typography>
                              : null
                          }


                        </Grid>
                      </Grid>
                      <Grid item sm={6} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid container item xs={12}>

                              <Grid item xs={6}>
                                <Typography align="right" variant="subtitle1"> Total Mrp  : </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography align="right" variant="body2">
                                  &#8377;  {singleOrder.products && singleOrder.products.length>0&& singleOrder.products.reduce((acc, cur) => acc + cur.mrp * cur.quantity, 0)}
                                </Typography>
                              </Grid>
                              </Grid>
                              {
                                (singleOrder?.codCharge) ? <>
                                  <Grid item xs={6}>
                                    <Typography align="right" variant="subtitle1">Cod Charge  : </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography align="right" variant="body2">
                                      &#8377;  {singleOrder.codCharge}
                                    </Typography>
                                  </Grid>
                                </>:null
                              }
                               <Grid container item xs={12} >
                              <Grid item xs={6}>
                                <Typography align="right" variant="subtitle1"> Total Quantity  : </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography align="right" variant="body2">
                                  {singleOrder.products&&singleOrder.products.reduce((acc, cur) => acc + cur.quantity, 0)}
                                </Typography>
                              </Grid>
                              </Grid>
                             
                              <Grid container item xs={12} >
                                <Grid item lg={4}>
                                  {/* Grid for Spacing */}
                                </Grid>
                                <Grid lg={8} md={12} sm={12} xs={12}>
                                  
                                  {singleOrder?.discountBreakupInfo1 ? singleOrder?.discountBreakupInfo1.map((disObj, i) => {
                                    return <Grid key={i} container justifyContent={"space-between"}>
                                      <Typography >{Object.keys(disObj)}</Typography>
                                      <Typography variant="h5"> - ₹{Math.round(Object.values(disObj))} </Typography>
                                    </Grid>
                                  }) : null}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider sx={{ bgcolor: "dark.main" }} />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography align="right" color="primary" variant="subtitle1" > Total : </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography align="right" color="primary" variant="subtitle1" > &#8377; {singleOrder.totalPayableAmount} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </SubCard>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </TabPanel>
    </MainCard>
  );
};

export default BulkOrderDetails;
