const initalValue = {
    readyValue: '2',
    breakValue: '',
    modeValue: 'Manual',
    attendance_Id: '',
    leadData: [],
    call_status: false,
    call_Index: 0,
    lead_id: '',
    phone_number:''

}

const dialerReducer = (state = initalValue, action) => {
    switch (action.type) {
        case 'INITIAL_STATE':
            return {
                ...state,
                readyValue: action.payload
            }

        case 'BREAK_STATE':
            return {
                ...state,
                breakValue: action.payload
            }

        case 'MODE_STATE':
            return {
                ...state,
                modeValue: action.payload
            }

        case 'ATTENDANCE_ID':
            return {
                ...state,
                attendance_Id: action.payload
            }
        case 'LEAD_DATA':
            return {
                ...state,
                leadData: action.payload
            }
        case 'CALL_STATUS':
            return {
                ...state,
                call_status: action.payload
            }

        case 'CALL_INDEX':
            return {
                ...state,
                call_Index: action.payload
            }

        case 'LEAD_ID':
            return {
                ...state,
                lead_id: action.payload
            }
        case 'LEAD_PHONENUMBER':
            return {
                ...state,
                phone_number: action.payload
            }


        default:
            return state;
    }
}

export default dialerReducer;