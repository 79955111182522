import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconMail,
    IconCalendar,
    IconNfc,
    IconClipboardList,
} from "@tabler/icons";

// constant
const icons = {
    IconUserCheck: IconUserCheck,
    IconBasket: IconBasket,
    IconMessages: IconMessages,
    IconMail: IconMail,
    IconCalendar: IconCalendar,
    IconNfc: IconNfc,
    IconClipboardList: IconClipboardList,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
let role = localStorage.getItem('setRole')
let phoneNumber = localStorage.getItem('phoneNumber');
let item = [];
let item2 = [{
    id: "Whatsapp Marketing",
    title: <FormattedMessage id="Whatsapp Marketing" />,
    type: "item",
    url: "/marketing/whatsapp-marketing",
},
{
    id: "SMS Marketing",
    title: <FormattedMessage id="SMS Marketing" />,
    type: "item",
    url: "/marketing/sms-templates",
},

]
const accessNumbers = ["9911034338", "9177139113", "9719836187", '8076833686'];
const accessNumbers1 = ['9999675562', '9911034338', '8826345311', '9811415389', '8076833686']

if (accessNumbers1.includes(phoneNumber) || role == '1') {
    item = [
        {
            id: "Coupons Report",
            title: <FormattedMessage id="Coupons Report" />,
            type: "item",
            url: "/marketing/coupons-report",
        },
        {
            id: "Organic & Inorganic Report",
            title: <FormattedMessage id="Organic & Inorganic Report" />,
            type: "item",
            url: "/marketing/organic-inorganic-report",
        },
        {
            id: "Membership Report",
            title: <FormattedMessage id="Membership Report" />,
            type: "item",
            url: "/marketing/membership-report",
        },
        {
            id: "Deals Report",
            title: <FormattedMessage id="Deals Report" />,
            type: "item",
            url: "/marketing/deals-report",
        },
        {
            id: "Free Trial Report",
            title: <FormattedMessage id="Free Trial Report" />,
            type: "item",
            url: "/marketing/free-trial-report",
        },
        // {
        //     id: "Add Deals",
        //     title: <FormattedMessage id="Add Deals" />,
        //     type: "item",
        //     url: "/coupon/deal-report",
        //     breadcrumbs: true,
        // },

        {
            id: "Home Banners",
            title: <FormattedMessage id="Home Banners" />,
            type: "item",
            url: "/coupon/updateBanners",
            breadcrumbs: true,
        },

        {
            id: "All Deals",
            title: <FormattedMessage id="All Deals" />,
            type: "item",
            url: "/coupon/all-deals",
            breadcrumbs: true,
        },
        {
            id: "Create Deals",
            title: <FormattedMessage id="Create Deals" />,
            type: "item",
            url: "/coupon/create-deals/",
            breadcrumbs: true,
        },
        {
            id: "Testimonials Banners",
            title: <FormattedMessage id="Testimonials Banners" />,
            type: "item",
            url: "/marketing/testimonials-banners/",
            breadcrumbs: true,
        },
        {
            id: "Whatsapp Banners",
            title: <FormattedMessage id="Whatsapp Banners" />,
            type: "item",
            url: "/marketing/whatsapp-banners/",
            breadcrumbs: true,
        },
        {
            id: "Manage TV Banners",
            title: <FormattedMessage id="Manage TV Banners" />,
            type: "item",
            url: "/marketing/manage-tv-banners",
        },
        {
            id: "Special Deal Section",
            title: <FormattedMessage id="Special Deal Section" />,
            type: "item",
            url: "/marketing/special-deal-section",
            breadcrumbs: true,
        },
        {
            id: "Organic Cohort Report",
            title: <FormattedMessage id="Organic Cohort Report" />,
            type: "item",
            url: "/marketing/organic-cohort-report",
            breadcrumbs: true,
        },
        {
            id: "Color Notification",
            title: <FormattedMessage id="Color Notification" />,
            type: "item",
            url: "/marketing/color-notification",
            breadcrumbs: true,
        },
        {
            id: "Lightning Sale Products",
            title: <FormattedMessage id="Lightning Sale Products" />,
            type: "item",
            url: "/marketing/lightning-sale-products",
            breadcrumbs: true,
        },
        {
            id: "Survey Dashboard",
            title: <FormattedMessage id="Survey Dashboard" />,
            type: "item",
            url: "/marketing/survey-dashboard",
            breadcrumbs: true,
        },
        {
            id: "Combo Finder",
            title: <FormattedMessage id="Combo Finder" />,
            type: "item",
            url: "/marketing/top-three-products",
            breadcrumbs: true,
        },
        {
            id: "Subscription Report",
            title: <FormattedMessage id="Subscription Report" />,
            type: "item",
            url: "/marketing/subscription-report",
        }

    ]
    if (accessNumbers.includes(phoneNumber)) {
        item.push(
            {
                id: "Free Trail Products",
                title: <FormattedMessage id="Free Trail Products" />,
                type: "item",
                url: "/marketing/free-trail-products",
            }
        )
    }
} else if (role == "4") {
    item = [{
        id: "Free Trail Products",
        title: <FormattedMessage id="Free Trail Products" />,
        type: "item",
        url: "/marketing/free-trail-products",
    }]
    item2 = []
} else {
    item = [{
        id: "Coupons Report",
        title: <FormattedMessage id="Coupons Report" />,
        type: "item",
        url: "/marketing/coupons-report",
    },
    {
        id: "Organic & Inorganic Report",
        title: <FormattedMessage id="Organic & Inorganic Report" />,
        type: "item",
        url: "/marketing/organic-inorganic-report",
    },
    {
        id: "Membership Report",
        title: <FormattedMessage id="Membership Report" />,
        type: "item",
        url: "/marketing/membership-report",
    },
    {
        id: "Deals Report",
        title: <FormattedMessage id="Deals Report" />,
        type: "item",
        url: "/marketing/deals-report",
    },
    {
        id: "Free Trial Report",
        title: <FormattedMessage id="Free Trial Report" />,
        type: "item",
        url: "/marketing/free-trial-report",
    },


    {
        id: "Home Banners",
        title: <FormattedMessage id="Home Banners" />,
        type: "item",
        url: "/coupon/updateBanners",
        breadcrumbs: true,
    },

    {
        id: "Testimonials Banners",
        title: <FormattedMessage id="Testimonials Banners" />,
        type: "item",
        url: "/marketing/testimonials-banners/",
        breadcrumbs: true,
    },
    {
        id: "Whatsapp Banners",
        title: <FormattedMessage id="Whatsapp Banners" />,
        type: "item",
        url: "/marketing/whatsapp-banners/",
        breadcrumbs: true,
    },
    {
        id: "Special Deal Section",
        title: <FormattedMessage id="Special Deal Section" />,
        type: "item",
        url: "/marketing/special-deal-section",
        breadcrumbs: true,
    },
    {
        id: "Organic Cohort Report",
        title: <FormattedMessage id="Organic Cohort Report" />,
        type: "item",
        url: "/marketing/organic-cohort-report",
        breadcrumbs: true,
    },
    {
        id: "Color Notification",
        title: <FormattedMessage id="Color Notification" />,
        type: "item",
        url: "/marketing/color-notification",
        breadcrumbs: true,
    },
    {
        id: "Lightning Sale Products",
        title: <FormattedMessage id="Lightning Sale Products" />,
        type: "item",
        url: "/marketing/lightning-sale-products",
        breadcrumbs: true,
    },
    {
        id: "Survey Dashboard",
        title: <FormattedMessage id="Survey Dashboard" />,
        type: "item",
        url: "/marketing/survey-dashboard",
        breadcrumbs: true,
    },
    {
        id: "Combo Finder",
        title: <FormattedMessage id="Combo Finder" />,
        type: "item",
        url: "/marketing/top-three-products",
        breadcrumbs: true,
    },
    ]
}

if (role == '17') {
    item = []
    item2 = []
    if (phoneNumber == '9891769967' || phoneNumber == '9268096291'||phoneNumber=='8826956878') {
        item = [
            {
                id: "Deals Report",
                title: <FormattedMessage id="Deals Report" />,
                type: "item",
                url: "/marketing/deals-report",
            },
        ]
    }
}

if (role == '16') {
    item = []
    item2 = []
    item = [
        {
            id: "Testimonials Banners",
            title: <FormattedMessage id="Testimonials Banners" />,
            type: "item",
            url: "/marketing/testimonials-banners/",
            breadcrumbs: true,
        },
    ]
}


export const MarketingTab = {
    id: "Marketing Reports",
    title: <FormattedMessage id="Marketing Reports" />,
    type: "group",
    children: [
        {
            id: "Marketing Reports",
            title: <FormattedMessage id="Marketing Reports" />,
            type: "collapse",
            icon: icons["IconClipboardList"],
            children: item
        },
        {
            id: "Seller Website",
            title: <FormattedMessage id="Seller Website" />,
            type: "collapse",
            icon: icons["IconClipboardList"],
            children: item2
        },
    ],
};
