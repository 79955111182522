import axios from "axios";
// import DateWiseDownloadData from "../../ui-component/tabels/DateWiseDownloadData";
import { useState, useEffect } from "react";
import moment from "moment";
import DateWiseDownloadData from "../../ui-component/tabels/DateWiseDownloadData";


let headers = [
    { label: "Numeric Order Id", key: 'numericOrderId' },
    { label: "Created At", key: 'createdAt' },
    { label: "Delivered Date", key: 'deliveredTime' },
    { label: "Pay Mode", key: 'paymentMethod' },
    { label: "MRP of Delivered Orders", key: 'mrpOfDeliveredOrder' },
    { label: "Order Payable", key: 'orderValueOfDeliveredOrder' },
    { label: "Credit Note", key: 'creditNotePerOrder' },
    { label: "Prepaid Orders Amount", key: 'partialPaymentOrderAmount' },
    { label: "Amount Recoverable or Payable", key: 'amountRecoverableOrPayablePerOrder' },
    { label: "Commission Amount", key: 'commissionAmountPerOrder' },
    { label: "Net Payable or Recoverable", key: 'netPayableOrRecoverablePerOrder' },
    { label: "Balance", key: 'balance' },
    { label: "Settled or Not Settled", key: 'isSettlementDone' },
    { label: "Settlement Date", key: 'payOutAt' },
    { label: "UTR No", key: 'utr' },

]

const paymentMode = {
    1: "Cash",
    2: "Online",
    3: "Rupifi",
    4: "Venue",
    5: "Mela/PineLabs",
    6: "Partially Paid"
}

export default function FCOrderWiseSettlementReport(props) {
    const { isFc } = true
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState({
        startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
        endDate: new Date().toISOString().slice(0, 10)
    });
    const getData = () => {
        setLoading(true)
        setData([])
        let obj = { startDate: date.startDate, endDate: date.endDate, isFc : true }
 
        axios.post(`${process.env.REACT_APP_PATH}/fulfilment/settlement/getSettlementReportOrderWiseBreakup`, obj)
            .then(res => {
                if (res?.data?.headers?.success) {
                    let temp = res?.data?.body?.map((item) => {
                        return { ...item, paymentMethod: paymentMode[item?.paymentMethod] }
                    })
                    setData(temp)
                } else {
                    alert(res?.data?.message || "something went wrong")
                }
            }).catch(err => {
                alert(err?.response?.data?.message || "something went wrong")
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <DateWiseDownloadData date={date} headers={headers} data={data} getData={getData} loading={loading} setDate={setDate} />
        </>
    )
}
