import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import MainCard from "../../ui-component/cards/MainCard";
import SubCard from "../../ui-component/cards/SubCard";
import axios from "axios";

function EditBulkOrderScm() {
  const { OrderId, canApprove, canDispatch } = useParams();
  const router = useHistory();
  console.log("canApprovecanApprove", canApprove);
  const agentRole = localStorage.getItem("setRole");
  const agentType = localStorage.getItem("agentType");
  const agentAccessRole = ["5"];
  const [loading, setLoading] = useState(true);
  const [sellerDetails, setSellerDetails] = useState({
    name: "",
    phoneNumber: "",
    address: {},
  });
  const [orderDetails, setOrderDetails] = useState({});
  const [productList, setProductList] = useState([]);
  const agentRoleFromLocal = localStorage.getItem("agentRole");
  const agentPhoneNumber = localStorage.getItem("phoneNumber");
  const getAvaileableQuantity = async (
    sellerId,
    productId,
    quantity,
    EANCode
  ) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_PATH}/products/checkUcInventory`,
        {
          sellerId: sellerId,
          productId: productId,
          quantity: quantity,
          agentRole: agentRoleFromLocal,
          agentPhoneNumber: agentPhoneNumber,
          crmOrder: true,
          EANCode: EANCode,
        }
      );
      if (response.data.success) {
        return response.data.data;
      } else return 0;
    } catch (error) {
      return 0;
    }
  };

  const getOrderProducts = () => {
    axios
      .get(`${process.env.REACT_APP_PATH}/sales/getBulkOrderById/${OrderId}`)
      .then(async (res) => {
        if (res.data.success) {
          let temp = [];
          if (res.data.data.products && res.data.data.products.length > 0) {
            for (let i = 0; i < res.data.data.products.length; i++) {
              let proTemp = res.data.data.products[i];
              // let check product is combo or not
              let limit = await getAvaileableQuantity(
                res.data.data.sellerId,
                proTemp._id,
                proTemp.quantity,
                proTemp?.EANCode
              );
              proTemp.availableQuantity = limit;
              proTemp.pendingQuantity =
                limit >= proTemp.quantity ? 0 : proTemp.quantity - limit;
              proTemp.dispatchQuantity =
                limit >= proTemp.quantity ? proTemp.quantity : limit;
              temp.push(proTemp);
            }
            setOrderDetails(res.data.data);
            setProductList(temp);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("error of get product", error);
      });
  };

  const changeDispatchQuantity = (productId, value) => {
    value = Number(value);
    console.log("typee", typeof value);
    if (typeof value == "number") {
      let allProducts = [...productList];
      allProducts.forEach((item) => {
        if (item.productId == productId) {
          item.dispatchQuantity =
            value <= item.quantity
              ? value <= item.availableQuantity
                ? value
                : item.quantity - item?.availableQuantity
              : item.quantity;
          item.pendingQuantity =
            item.quantity -
            (value <= item.quantity
              ? value <= item.availableQuantity
                ? value
                : item.quantity - item?.availableQuantity
              : item.quantity);
        }
      });
      setProductList(allProducts);
    }
  };
  const changeRemark = (productId, value) => {
    let allProducts = [...productList];
    allProducts.forEach((item) => {
      if (item.productId == productId) {
        item.remark = value;
      }
    });
    setProductList(allProducts);
  };

  const createOrder = () => {
    let approveProducts = [];
    let pendingProducts = [];
    productList.forEach((pro) => {
      if (pro.pendingQuantity == 0) {
        approveProducts.push(pro);
      } else {
        if (pro.dispatchQuantity > 0) {
          approveProducts.push({
            ...pro,
            quantity: pro.dispatchQuantity,
          });
        }
        pendingProducts.push({
          ...pro,
          quantity: pro.pendingQuantity,
        });
      }
    });
    approveProducts.unshift("approved");

    if (pendingProducts.length > 0) {
      pendingProducts.unshift("pending");
    }

    if (pendingProducts.length > 0) {
      axios
        .post(`${process.env.REACT_APP_PATH}/sales/createAndSplitBulkOrders`, {
          orders: [approveProducts, pendingProducts],
          mainOrderId: OrderId,
          needToApprove: true,
        })
        .then((res) => {
          console.log("res of spilt", res.data);
          if (res.data.success) {
            alert("Order split successfully");
            router.goBack();
          } else {
            alert(res.data?.message || "someting went wrong");
          }
        })
        .catch((error) => {
          console.log("error ", error);
          alert("someting went wrong");
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
          orderId: OrderId,
          action: "approved", // notApproved
        })
        .then((res) => {
          console.log("res of approve ", res.data);
          if (res.data.success) {
            alert("Order split successfully");
            router.goBack();
          } else {
            alert(res.data?.message || "someting went wrong");
          }
        })
        .catch((error) => {
          console.log("error of approve", error);
          alert("someting went wrong");
        });
    }
  };

  const approveByAgent = () => {
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
        orderId: OrderId,
        action: "approved", // notApproved
      })
      .then((res) => {
        console.log("res of approve ", res.data);
        if (res.data.success) {
          alert(res.data?.message || "Order approved successfully");
          router.goBack();
        } else {
          alert(res.data?.message || "someting went wrong");
        }
      })
      .catch((error) => {
        console.log("error of approve", error);
        alert("Someting went wrong");
      });
  };

  const rejectOrder = () => {
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/approveOrAddQuantity`, {
        orderId: OrderId,
        action: "notApproved", // notApproved
      })
      .then((res) => {
        if (res.data.success) {
          alert(res.data?.message || "Order reject successfully");
          router.goBack();
        } else {
          alert(res.data?.message || "someting went wrong");
        }
        console.log("res of approve ", res.data);
      })
      .catch((error) => {
        console.log("error of approve", error);
        alert("Someting went wrong");
      });
  };

  useEffect(() => {
    getOrderProducts();
  }, []);

  if (loading) {
    return (
      <div style={{ backgroundColor: "rgba(0,0,0,.4)", height: "100vh" }}>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Grid>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="Parent Order Id"
              value={orderDetails?.parentBulkNumericOrderId}
              label="Parent Order Id"
              fullWidth
            />
          </Grid>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="numericOrder"
              value={orderDetails?.numericOrderId}
              label="Order Id"
              fullWidth
            />
          </Grid>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="Name"
              value={orderDetails?.name}
              label="Name"
              fullWidth
            />
          </Grid>
          <Grid item md={3} lg={3} sm={6} xs={12}>
            <TextField
              name="phoneNumber"
              value={orderDetails?.phoneNumber}
              label="Phone Number"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item md={9} lg={9} sm={12} xs={12}>
            <SubCard title={"Sku in order"}>
              <Table style={{ width: "100%" }}>
                <TableContainer style={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>MRP</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Available Quantity</TableCell>
                      <TableCell>Dispatch Quantity</TableCell>
                      <TableCell>Pending Quantity</TableCell>
                      <TableCell sx={{width:200}}>Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productList &&
                      productList.map((product) => (
                        <TableRow>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>
                            {product?.mrp || product.menuPrice}
                          </TableCell>
                          <TableCell>{product.quantity}</TableCell>
                          <TableCell>{product.availableQuantity}</TableCell>
                          <TableCell>
                            <TextField
                              disabled={
                                (["4"].includes(agentRole) ||
                                  ["9719836187"].includes(agentPhoneNumber)) &&
                                canDispatch == "true"
                                  ? false
                                  : true
                              }
                              value={product.dispatchQuantity}
                              onChange={(e) =>
                                changeDispatchQuantity(
                                  product.productId,
                                  e.target.value
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>{product.pendingQuantity}</TableCell>
                          <TableCell>
                            <TextField
                              disabled={
                                (["4"].includes(agentRole) ||
                                  ["9719836187"].includes(agentPhoneNumber)) &&
                                canDispatch == "true"
                                  ? false
                                  : true
                              }
                              value={product.remark}
                              onChange={(e) =>
                                changeRemark(product.productId, e.target.value)
                              }
                              title="Remark"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </TableContainer>
              </Table>
            </SubCard>
          </Grid>
          <Grid item md={3} lg={3} xs={12} sm={12}>
            <SubCard title="Delivery address" sx={{ position: "relative" }}>
              <div style={{ maxHeight: 200, overflow: "scroll" }}>
                <Typography>Name : {orderDetails?.address?.name}</Typography>
                <Typography>
                  phone Number : {orderDetails?.address?.phoneNumber}
                </Typography>
                <Typography>
                  Address 1 : {orderDetails?.address?.address1}
                </Typography>
                <Typography>
                  Address 2 : {orderDetails?.address?.address2}
                </Typography>
                <Typography>
                  Pincode : {orderDetails?.address?.postalCode}
                </Typography>
              </div>
            </SubCard>
            <SubCard title={"price Details"} sx={{ marginTop: 5 }}>
              <Grid xs={12} container justifyContent={"space-between"}>
                <Typography align="left" variant="subtitle1">
                  {" "}
                  Grand Total :{" "}
                </Typography>

                <Typography align="left" variant="subtitle1">
                  {" "}
                  &#8377; {orderDetails?.totalPayableAmount}{" "}
                </Typography>
              </Grid>
              {agentRole != 5 && (
                <Grid xs={12} container justifyContent={"space-between"}>
                  <Typography align="left" variant="subtitle1">
                    {" "}
                    New Price :{" "}
                  </Typography>

                  <Typography align="left" variant="subtitle1">
                    {" "}
                    &#8377;{" "}
                    {Math.ceil(
                      productList.reduce(
                        (acc, item) =>
                          (acc += item.dispatchQuantity * item.price),
                        0
                      )
                    )}{" "}
                  </Typography>
                </Grid>
              )}
            </SubCard>
            {canApprove == "true" ? (
              <>
                {agentRole == 4 || agentPhoneNumber == "9719836187" ? (
                  <Button
                    style={{ marginTop: 10 }}
                    variant="contained"
                    fullWidth
                    onClick={createOrder}
                  >
                    Submit
                  </Button>
                ) : (
                  ((agentRole == 5 && agentType == "BULK") ||
                    agentRole == 14) && (
                    <Grid container spacing={2} mt={2}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={rejectOrder}
                          style={{
                            backgroundColor: "red",
                          }}
                        >
                          Reject
                        </Button>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={approveByAgent}
                        >
                          Approve
                        </Button>
                      </Grid>
                    </Grid>
                  )
                )}
              </>
            ) : null}
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  );
}

export default EditBulkOrderScm;
