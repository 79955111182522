import React, { createContext, useEffect, useReducer } from 'react';

// third-party
// import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from '../store/actions';
import accountReducer from '../store/accountReducer';

// project imports
// import axios from '../utils/axios';
import Loader from '../ui-component/Loader';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { dialerState, storeId } from '../action/dialerAction';
// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    // user: null
};
// const time = localStorage.getItem('tokenTime')
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
let getTokenTime = parseJwt(localStorage.getItem('serviceToken'))

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    else {
        return getTokenTime?.exp * 1000 - Date.now() - 1000 * 60 * 3 > 0;
    }
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${serviceToken}`;
        axios.interceptors.response.use(response => response, error => {
            if (error?.response?.status === 403) {
                localStorage.removeItem('serviceToken');
                localStorage.removeItem('name')
                delete axios.defaults.headers.common["Authorization"];
                window.location.reload()
            } else {
                return Promise.reject(error);
            }
        })

    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('name')
        delete axios.defaults.headers.common["Authorization"];
    }
};

//-----------------------|| JWT CONTEXT & PROVIDER ||-----------------------//

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => { }
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const history = useHistory()
    const location = useLocation()

    const login = async (userName, password) => {
        const reqObj = {
            userName: userName,
            password: password
        }
        try {
            const response = await axios.post(process.env.REACT_APP_PATH + "/admin/login", reqObj)
            if (response.data.data.message !== "Invalid credentials") {
                setSession(response.data.data.token);
                localStorage.setItem("agentType", response.data.data.agentType);
                localStorage.setItem('setRole', response.data.data.role);
                localStorage.setItem("phoneNumber", response.data.data.phoneNumber)
                localStorage.setItem('servetel', response.data.data.servetelApiKey);
                localStorage.setItem('tikona', response.data.data.tikonaAgentId);
                localStorage.setItem('cohortTeam', response.data.data.cohortTeam);
                localStorage.setItem('name', response.data.data.name);
                localStorage.setItem('cohortType', response.data.data.cohortType);
                localStorage.setItem('tableNumber', response.data.data.tableNumber);
                dispatch(dialerState('2'));
                dispatch({
                    type: LOGIN,
                });
                if (sessionStorage.getItem('url') != null) {
                    history.push(sessionStorage.getItem('url'))
                    sessionStorage.removeItem('url')
                }
                window.location.reload()
            }
        } catch (e) {
            alert("User Name and Password Not Match")
        }

    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        dispatch(storeId(''))
        dispatch(dialerState('2'));
        sessionStorage.setItem('url', location.pathname)
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/account/me');
                    // const { user } = response.data;
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            // user
                        }
                    });

                    if (sessionStorage.getItem('url') != null) {
                        history.push(sessionStorage.getItem('url'))
                        sessionStorage.removeItem('url')
                    }

                } else {
                    localStorage.removeItem('serviceToken');
                    sessionStorage.setItem('url', location.pathname)
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            // user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        // user: null
                    }
                });
                sessionStorage.setItem('url', location.pathname)
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
