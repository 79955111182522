import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
} from "@tabler/icons";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconClipboardList: IconClipboardList,
};
const role = localStorage.getItem("setRole");
//-----------------------|| Customer MENU ITEMS ||-----------------------//

let child_items_arr = [
  {
    id: "Livetraining",
    title: <FormattedMessage id="Live Training" />,
    type: "item",
    url: "/livetraining",
  },
  {
    id: "Add Trainer",
    title: <FormattedMessage id="Add Trainer" />,
    type: "item",
    url: "/addtrainer",
  },
  {
    id: "Add Course",
    title: <FormattedMessage id="Add Course" />,
    type: "item",
    url: "/addcourse",
  },
  {
    id: "Trainer's Report",
    title: <FormattedMessage id="Trainer's Report" />,
    type: "item",
    url: "/trainerreport",
  },
  {
    id: "Trainer's Report Class wise",
    title: <FormattedMessage id="Trainer's Report Class wise" />,
    type: "item",
    url: "/trainerreportclasswise",
  },
  {
    id: "Slot Booking",
    title: <FormattedMessage id="Slot Booking" />,
    type: "item",
    url: "/slot-booking",
  },

  {
    id: "All Meetings",
    title: <FormattedMessage id="All Meetings" />,
    type: "item",
    url: "/all-meetings",
  },
  {
    id: "Shorts Uploading",
    title: <FormattedMessage id="Shorts Uploading" />,
    type: "item",
    url: "/shorts-uploading",
  },
  {
    id: "Shorts Reports",
    title: <FormattedMessage id="Shorts Reports" />,
    type: "item",
    url: "/shorts-reports",
  },
  {
    id: "Live Training Recordings",
    title: <FormattedMessage id="Live Training Recordings" />,
    type: "item",
    url: "/live-training-recordings",
  },
  {
    id: "Live Page Banner",
    title: <FormattedMessage id="Live Page Banner" />,
    type: "item",
    url: "/live-page-banner",
  },
  {
    id: "Readymade Services List",
    title: <FormattedMessage id="Readymade Services List" />,
    type: "item",
    url: "/readymade-services-list",
  },
  {
    id: "Hair & Scalp,Skin Analysis Report",
    title: <FormattedMessage id="Hair & Scalp,Skin Analysis Report" />,
    type: "item",
    url: "/hair-scalp-skin-analysis-report",
  },
  {
    id: "Mela Creation",
    title: <FormattedMessage id="Mela Creation" />,
    type: "item",
    url: "/mela-creation",
  },
  {
    id: "Superstar Report",
    title: <FormattedMessage id="Superstar Report" />,
    type: "item",
    url: "/superstar-report",
  },
  {
    id: "WatchTime Report",
    title: <FormattedMessage id="WatchTime Report" />,
    type: "item",
    url: "/watch-time-report",
  },
]



const viewAsPerRole = () => {
  if (role == 1 || role == 16) {
    child_items_arr.push({
      id: "Detailed Slot Data",
      title: <FormattedMessage id="Detailed Slot Data" />,
      type: "item",
      url: "/detailed-slot-data",
    })
  }
  return (
    [
      {
        id: "Lives",
        title: <FormattedMessage id="Live" />,
        type: "collapse",
        icon: icons["IconClipboardList"],
        children: child_items_arr

      },
    ]
  )
};

export const Zoom = {
  id: "Lives",
  title: <FormattedMessage id="Training" />,
  type: "group",
  children: viewAsPerRole()
};