export const dialerState = (data) => (dispatch) => {
    dispatch({ type: 'INITIAL_STATE', payload: data })
}

export const breakState = (data) => (dispatch) => {
    dispatch({ type: 'BREAK_STATE', payload: data })
}

export const modeState = (data) => (dispatch) => {
    dispatch({ type: 'MODE_STATE', payload: data })
}

export const storeId = (data) => (dispatch) => {
    dispatch({ type: 'ATTENDANCE_ID', payload: data })
}

export const getLeadsData = (data) => (dispatch) => {
    dispatch({ type: 'LEAD_DATA', payload: data })
}

export const getCallIndex = (data) => (dispatch) => {
    dispatch({ type: 'CALL_INDEX', payload: data })
}

export const getCallStatus = (data) => (dispatch) => {
    dispatch({ type: 'CALL_STATUS', payload: data })
}

export const getLeadId = (data) => (dispatch) => {
    dispatch({ type: 'LEAD_ID', payload: data })
}

export const getLeadPhoneNumber = (data) => (dispatch) => {
    dispatch({ type: 'LEAD_PHONENUMBER', payload: data })
}

 

 