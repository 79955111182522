import { Grid, TextField } from "@material-ui/core"
import { LoadingButton } from "@mui/lab"
import { CSVLink } from "react-csv";

export default function DateWiseDownloadData(props) {
    const { headers, getData, data, loading, setDate, date } = props

    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} lg={2}>
                    <TextField
                        id="datetime-local"
                        label="Start Date"
                        type="date"
                        defaultValue={date.startDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: "100%", }}
                        size="small"
                        onChange={(e) => setDate({ ...date, startDate: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField
                        id="datetime-local"
                        label="End Date"
                        type="date"
                        defaultValue={date.endDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            width: "100%",
                        }}
                        onChange={(e) => setDate({ ...date, endDate: e.target.value })}
                        size="small"
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <LoadingButton size="small" loading={loading} onClick={() => getData()} variant="contained" >Submit</LoadingButton>
                </Grid>
                {(data?.length > 0) && <Grid item xs={3}>
                    <CSVLink filename={`SettlementReport.csv`} data={data} headers={headers} style={{ textDecoration: 'none', border: '1px solid #999', padding: '10px 20px', backgroundColor: '#95c7f1' }}>Download
                    </CSVLink>
                </Grid>}
            </Grid>
        </>
    )
}
