import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';

// load mock apis
import './_mockApis';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import reducer from './store/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';



// style + assets
import './assets/scss/style.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";
import { persistedReducer } from './store/reducer';


//-----------------------|| REACT DOM RENDER  ||-----------------------//
const store = createStore(persistedReducer, applyMiddleware(thunk));
const Persistor = persistStore(store);

// const store = createStore(reducer, applyMiddleware(thunk));
// if (process.env.NODE_ENV === 'production') {
//     console.log = () => {}
//     console.debug = () => {}
//   }



  

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}> 
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
